<template>
  <el-dialog
    :title="$t(`lang.${event.dialogTitle}`)"
    :visible.sync="visible"
    width="700px"
    class='booking_create_box'
    :close-on-click-modal="false"
    :before-close="handleClose">
    <el-form
      :model="dialogValObj"
      status-icon
      :rules="rules"
      ref="event"
      label-width="127px"
      class="create_booking_box">
      <div class="form_content">
        <!-- start 定制化CC -->
        <el-form-item
          :label="$t('lang.Type')"
          v-if="enableCustomizedCC">
          <el-radio-group
            v-model="dialogValObj.eventType"
            :disabled="event.editFlag"
            @change="changeEventType">
            <el-radio :label="0">PGC</el-radio>
            <el-radio :label="1">UGC</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- stop 定制化CC -->
        <el-form-item
          :label="$t('lang.scheduleTitle')"
          prop="title">
          <el-input
            type="text"
            v-model="dialogValObj.title"
            autocomplete="off"
            maxlength="32"></el-input>
        </el-form-item>
        <el-form-item :label="$t('lang.reporter')">
          <el-input
            type="text"
            v-model="dialogValObj.reporter"
            maxlength="32"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('lang.storySlug')">
          <el-input
            type="textarea"
            cols="30"
            rows="2"
            maxlength="255"
            v-model="dialogValObj.description"></el-input>
        </el-form-item>
        <el-form-item
          :label="`${$t('lang.time')}`"
          prop="duration">
          <el-date-picker
            v-model="dialogValObj.startTime"
            popper-class='pick_date_timebk'
            class='create_box_time'
            @change='changeStartTime'
            :picker-options="pickerDateBeg"
            type="datetime">
          </el-date-picker>
          <!-- :append-to-body="false" -->
          <span
            class='f_left'
            style='margin:0 10px;'>{{$t('lang.to')}}</span>
          <el-date-picker
            v-model="dialogValObj.endTime"
            popper-class='pick_date_timebk'
            @change='changeEndTime'
            :picker-options="pickerDateEnd"
            type="datetime"
            class='create_box_time stop_time'>
          </el-date-picker>
          <!-- :append-to-body="false" -->
          <span
            class='f_left'
            style='margin-left:10px'>{{$t('lang.duration')}}：</span>
          <span class="f_left duration">{{dialogValObj.duration}}</span>
        </el-form-item>
        <el-form-item
          :label="$t('lang.deviceSourceScte')"
          prop="tpeerid"
          v-if="dialogValObj.eventType == 0 && enableCustomizedCC || !enableCustomizedCC">
          <i
            class="arrow-icon"
            @click="changeVisible('handle')"
            :class="[visibleStatusS ? 'el-icon-arrow-up': 'el-icon-arrow-top']"></i>
          <el-select
            filterable
            placeholder=''
            v-model="dialogValObj.tpeerid"
            v-scroll="bindSScroll"
            @focus="getSourceList"
            remote
            :remote-method='searchSource'
            ref='source'
            @blur="initSearchCondi"
            @change="changeSource"
            @visible-change='changeVisible'
            :disabled="sourceFlag"
            :popper-append-to-body="false">
            <el-option
              v-for="item in sourceList"
              :key="item.peerId"
              :label="item.name"
              :value="item.peerId"
              :title='item.name'>
            </el-option>
          </el-select>
        </el-form-item>
        <!-- start 定制化CC -->
        <el-form-item
          v-else
          :label="$t('lang.deviceSourceScte')"
          prop="ugcAccountId">
          <el-input
            type="text"
            v-model="dialogValObj.ugcAccountId"
            autocomplete="off"
            maxlength="32"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('lang.channelType')"
          v-if="enableCustomizedCC">
          <el-radio-group
            v-model="dialogValObj.auto"
            :disabled="dialogValObj.ttype != 'T' && dialogValObj.eventType == 0"
            @change="changeAuto">
            <el-radio :label="0">{{ $t('lang.manual') }}</el-radio>
            <el-radio :label="1">{{ $t('lang.automatic') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="$t('lang.channelGroup')"
          v-if="enableCustomizedCC">
          <el-select
            placeholder=''
            v-model="dialogValObj.userGroupId"
            popper-class='booking_create_box_channel'
            @change="changeGroup"
            :popper-append-to-body="false"
            v-scroll="bindGroupScroll">
            <el-option
              v-for="item in groupList"
              :key="item.id"
              :label="item.group"
              :value="item.id"
              :title='item.group'>
            </el-option>
          </el-select>
        </el-form-item>
        <!-- stop 定制化CC -->
        <el-form-item
          v-if="dialogValObj.auto == 0 && enableCustomizedCC || !enableCustomizedCC"
          :label="enableCustomizedCC? `${$t('lang.destinationChannel')}` : `${$t('lang.Channel')}`"
          prop="rpeerid">
          <i
            class="arrow-icon"
            @click="changeVisibleR('handle')"
            :class="[visibleStatusR ? 'el-icon-arrow-up': 'el-icon-arrow-top']"></i>
          <el-select
            filterable
            placeholder=''
            v-model="dialogValObj.rpeerid"
            v-scroll='bindRScroll'
            popper-class='booking_create_box_channel'
            ref='r'
            @focus="getRList"
            @change="changeR"
            remote
            :remote-method='searchR'
            @blur="initSearchCondi"
            @visible-change='changeVisibleR'
            :popper-append-to-body="false"
            :disabled="!allowSelectR">
            <el-option
              v-for="item in RList"
              :key="item.peerId"
              :label="item.name"
              :value="item.peerId"
              :title='item.name'>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('lang.outputAddress')">
          <el-select
            v-model="checkedSocial"
            multiple
            collapse-tags
            filterable
            @change="changeSocialList"
            default-first-option
            :popper-append-to-body="false"
            class="social_drop"
            placeholder="">
            <el-option
              v-for="item in socialList"
              :key="item.id"
              :label="item.nickName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <div class='delay_bit'>
          <el-form-item>
            <span style='display:inline-block;width:127px;'>{{`${$t('lang.delay')}(0.5-50s)`}}</span>
            <el-input
              v-if='sourceType!="scte"'
              v-model="dialogValObj.delay"
              v-input-delay-range='{updateValue:updateDelayValue, val:dialogValObj.delay, value: dialogValObj}'
              :disabled="disabled.delay"
              @blur='inputScope("delay", 0.5, 50)'></el-input>
            <span style='margin:0 12px 0 24px'>{{`${$t('lang.bitrate')}(100-10240Kbps)`}}</span>
            <el-input
              v-model="dialogValObj.bitrate"
              v-input-bit-range='{updateValue:updateBitValue}'
              :disabled="disabled.bitrate"
              @blur='inputScope("bitrate", 100, 10240)'></el-input>
            <!-- <span style='margin-left:-110px;margin-right:12px;'>{{`${$t('lang.duration')}`}}</span> -->
          </el-form-item>
        </div>
      </div>
      <div class="btn_box">
        <el-form-item>
          <el-button
            type="primary"
            :loading="saveLoading"
            v-repeat-click='{event:createBooking,params:["event"]}'>{{$t('lang.save')}}</el-button>
          <el-button @click="cancel('event')">{{$t('lang.cancel')}}</el-button>
        </el-form-item>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import Outil from '../../assets/js/utils.js'
import Bus from '@/assets/js/vueBus.js'
import { bookingAnalyze } from '@/assets/js/googleAnalyze/booking.js'
import toTips from '@/assets/js/toTipsObj'
import { listAccountInfo, checkSocialAccount, listBookingShareIds, pageUserSource, pageBookingChannel, listUserGroup } from '@/assets/api/sourceControl.js'
import filter from 'lodash.filter'
import findIndex from 'lodash.findindex'
export default {
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    event: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      dialogValObj: {},
      bindSScroll: {
        onScroll: this.handleSourceScroll,
        distance: 10,
        directive: '.el-select-dropdown__wrap.el-scrollbar__wrap'
      },
      bindRScroll: {
        onScroll: this.handleRScroll,
        distance: 10,
        directive: '.el-select-dropdown__wrap.el-scrollbar__wrap'
      },
      bindGroupScroll: {
        onScroll: this.handleGroupScroll,
        distance: 10,
        directive: '.el-select-dropdown__wrap.el-scrollbar__wrap'
      },
      selectedR: {
        name: ''
      },
      sourceType: '',
      disabled: {
        delay: true,
        bitrate: true
      },
      sourceParam: {
        pageNum: 1,
        pageSize: 15,
        condition: '',
        living: true,
        online: true,
        offline: true,
        pack: true,
        anywhere: true,
        gridPack: true,
        gridSdi: true,
        ext: true,
        bookmardIds: 'all',
        unClassified: true,
        token: true,
        globalGrid: true,
        gridViaToken: true,
        onlineFirst: true,
        liveFirst: true,
        battery: true,
        bookmarks: true,
        scte: false,
        booking: true,
        pn: 'bk',
        tokenRegardlessOfUser: false,
        noCamera: true
      },
      sourceTotalPag: 1,
      rParam: {
        sid: '', // UGC情况下该参数为空
        condition: '',
        auto: 0, // null全部，0自动，1手动
        pageNum: 1,
        pageSize: 15,
        booking: true,
        userGroupId: ''
      },
      rTotalPag: 1,
      createStopTime: '',
      dialogVisible: false,
      RList: [],
      sourceList: [],
      addFlag: true,
      pickerDateBeg: {
        disabledDate (time) {
          var date = Outil.getDateTime()
          return time.getTime() < new Date(date.year + '-' + date.month + '-' + date.day + ' 00:00:00').getTime()
        }
      },
      pickerDateEnd: {
        disabledDate (time) {
          var date = Outil.getDateTime()
          return time.getTime() < new Date(date.year + '-' + date.month + '-' + date.day + ' 00:00:00').getTime()
        }
      },
      visibleStatusS: false,
      autoClickFlagS: false,
      visibleStatusR: false,
      autoClickFlagR: false,
      checkedSocial: [],
      socialList: [],
      groupList: [],
      allowSelectR: false,
      groupObj: {
        pageSize: 10,
        pageNum: 1
      },
      groupTotalPag: 1,
      enableCustomizedCC: this.$store.state.common.baseUrl.enableCustomizedCC || false,
      sourceFlag: false,
      saveLoading: false
    }
  },
  created () {
    this.changeDisableVal(this.event.ttype)
    /*
      * 编辑时选中的nickName的回显，计算duration
      * 根据是新增还是编辑进行初始化赋值
      */
    const flag = this.event.editFlag
    const editObj = this.event
    this.dialogValObj = {
      eventId: flag ? editObj.eventId : '',
      title: flag ? editObj.title : '',
      rpeerid: flag ? editObj.rpeerid : '',
      tpeerid: flag ? editObj.tpeerid : '',
      ttype: flag ? editObj.ttype.toUpperCase() : '',
      startTime: flag ? editObj.startTime : '',
      endTime: flag ? editObj.endTime : '',
      description: flag ? editObj.description : '',
      delay: flag ? editObj.parameters.delay : '',
      bitrate: flag ? editObj.parameters.bitrate : '',
      duration: '',
      reporter: flag ? editObj.parameters.reporter : '',
      /*
      * color: flag? editObj.color : 'rgb(15, 190, 113)',
      * 定制化CC
      */
      auto: flag && editObj.auto != null ? editObj.auto : 0, // 自动 手动
      ugcAccountId: flag ? editObj.ugcAccountId : '', // UGC情况下输入ugcAccountId
      eventType: flag && editObj.eventType != null ? editObj.eventType : 0, // UGC 、PGC
      userGroupId: flag && editObj.userGroupId != null ? editObj.userGroupId : '', // 组名
      booking: true
    }
    this.initDestinationStatus()
    if (flag) {
      this.calcDuration(this.dialogValObj.startTime, this.dialogValObj.endTime)
      this.getSelectedSocial()
      this.getRList()
    }
    this.getSourceList()
    this.getSocialList()
    this.getlistUserGroup()
  },
  mounted () {
    this.$nextTick(() => { // 为了解决点击时间选择后，不关闭时间框就去点击source下拉出现的问题
      const nodeList = document.querySelectorAll('.create_box_time')
      nodeList.forEach(v => {
        v.addEventListener('focus', this.elClick, true)
      })
    })
  },
  computed: {
    rules () {
      return {
        title: [
          { required: true, message: this.$t('lang.titleEmpty'), trigger: 'blur' }
        ],
        duration: [
          { required: true, message: this.$t('lang.durEmpty'), trigger: 'blur' }
        ],
        tpeerid: [
          { required: true, message: this.$t('lang.sourceEmpty'), trigger: 'change' }
        ],
        rpeerid: [
          { required: true, message: this.enableCustomizedCC ? this.$t('lang.rDEmpty') : this.$t('lang.rEmpty'), trigger: 'change' }
        ],
        ugcAccountId: [
          { required: true, message: this.$t('lang.sourceEmpty'), trigger: 'change' }
        ]
        /*
        * 2020/12/1修改需求group改为非必选项
        * userGroupId: [
        *     { required: true, message: this.$t('lang.groupEmpty'), trigger: 'change' }
        * ]
        */
      }
    }
  },
  methods: {
    elClick () {
      $('.booking_create_box .el-dialog').click()
    },
    changeAuto (val) {
      if (this.event.auto == 1 && val == 0) {
        this.dialogValObj.rpeerid = ''
      }
    },
    changeEventType () {
      this.initDestinationStatus()
    },
    // 查询group分组
    getlistUserGroup () {
      if (this.groupTotalPag < this.groupObj.pageNum) return
      const params = {
        condition: '',
        pageSize: this.groupObj.pageSize,
        pageNum: this.groupObj.pageNum
      }
      listUserGroup(params).then(res => {
        if (res.data.errorCode == '0x0') {
          const result = res.data.result
          this.groupTotalPag = result.pages || 1
          if (this.groupObj.pageNum == 1) {
            this.groupList = result.list
            const temp = filter(this.groupList, { id: this.event.userGroupId })
            if (!temp.length && this.event.editFlag && result.list.length > 0) {
              this.groupList.push({
                id: this.event.userGroupId,
                group: this.event.userGroupName
              })
            }
          } else {
            if (this.event.editFlag) {
              const index = findIndex(result.list, { id: this.event.userGroupId })
              if (index >= 0) result.list.splice(index, 1)
            }
            this.groupList = this.groupList.concat(result.list)
          }
        } else {
          Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
        }
      }).catch(() => {})
    },
    getSelectedSocial () {
      const params = { eventId: this.dialogValObj.eventId }
      listBookingShareIds(params).then(res => {
        if (res.data.errorCode === '0x0') {
          const data = res.data.result
          data.map(item => {
            this.checkedSocial.push(item.id)
          })
        } else {
          Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
        }
      }).catch(() => {})
    },
    getSocialList () {
      const params = {
        nickName: '',
        type: 'Y,F,Z,Twitter,O,T,K',
        pageNum: 1,
        pageSize: 99999,
        detail: false
      }
      const protocol = window.location.protocol
      const mediaApiUrl = `${protocol}//${this.$store.state.common.baseUrl.mediaApiUrl}`
      listAccountInfo(params, mediaApiUrl).then(res => {
        if (res.data.errorCode === '0x0') {
          this.socialList = res.data.result.list
        } else {
          Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
        }
      }).catch(() => {})
    },
    changeSocialList (val) {
      this.checkedSocial = val
    },
    handleGroupScroll () {
      if (this.groupTotalPag < this.groupObj.pageNum) return
      this.groupObj.pageNum++
      this.getlistUserGroup()
    },
    // 源滚动处理事件
    handleSourceScroll () {
      if (this.sourceTotalPag < this.sourceParam.pageNum) return
      this.sourceParam.pageNum++
      this.getSourceList()
    },
    // R滚动处理事件
    handleRScroll () {
      if (this.rTotalPag < this.rParam.pageNum) return
      this.rParam.pageNum++
      this.getRList()
    },
    // 关闭弹窗
    handleClose () {
      this.$refs.event.resetFields()
      this.sourceParam.pageNum = 0
      this.$emit('closeBox', false)
    },
    // 切换r
    changeVisibleR (flag) {
      if (flag === 'handle') {
        if (!this.autoClickFlagR && !this.visibleStatusR) this.$refs.r.focus()
        this.autoClickFlagR = false
      } else {
        this.autoClickFlagR = true
        this.visibleStatusR = flag
        if (flag) {
          this.$refs.r.blur()
          this.$refs.r.focus()
        }
      }
    },
    // 切换source列表显示
    changeVisible (flag) {
      if (flag === 'handle') {
        if (!this.autoClickFlagS && !this.visibleStatusS) {
          this.$refs.source.focus()
        }
        this.autoClickFlagS = false
      } else {
        this.autoClickFlagS = true
        this.visibleStatusS = flag
        if (flag) {
          this.$refs.source.blur()
          this.$refs.source.focus()
        }
      }
    },
    // 创建预约事件
    createBooking (formName) {
      this.saveLoading = true
      bookingAnalyze('bookingCreateEventSave', '/bk')
      const verifyArr = ['title', 'duration']
      // 是否验证tpeerid
      if (this.dialogValObj.eventType == 0 && this.enableCustomizedCC || !this.enableCustomizedCC) {
        verifyArr.push('tpeerid')
      } else {
        verifyArr.push('ugcAccountId')
      }
      // 是否验证rpeerid
      if (this.dialogValObj.auto == 0 && this.enableCustomizedCC || !this.enableCustomizedCC) {
        verifyArr.push('rpeerid')
      }
      let errors = []
      this.$refs[formName].validateField(verifyArr, error => {
        if (error) {
          errors.push(error)
        }
      })
      if (errors.length > 0) {
        this.saveLoading = false
        return
      }
      this.sendReqCreateBooking(formName)
    },
    // 延迟和比特率输入的范围
    inputScope (key, min, max) {
      if (this.dialogValObj[key]) {
        this.dialogValObj[key] = this.dialogValObj[key].toString().replace(/[^\d.]/g, '')
        this.dialogValObj[key] = this.dialogValObj[key] < min ? min : this.dialogValObj[key]
        this.dialogValObj[key] = this.dialogValObj[key] > max ? max : this.dialogValObj[key]
        this.dialogValObj[key] = this.dialogValObj[key].toString()
      }
      if (this.dialogValObj[key] && this.dialogValObj[key].includes('.')) {
        const val = this.dialogValObj[key].split('.')
        this.dialogValObj[key] = `${val[0]}.${val[1]}`
        this.dialogValObj[key] = Number(val[1]) ? this.dialogValObj[key] : this.dialogValObj[key].split('.')[0]
      }
    },
    // 发送add或者updata请求
    async sendReqCreateBooking (formName) {
      this.inputScope('delay', 0.5, 50)
      this.inputScope('bitrate', 100, 10240)
      this.dialogValObj.title = this.dialogValObj.title.trim()
      this.dialogValObj.startTime = typeof this.dialogValObj.startTime == 'object' ? this.dialogValObj.startTime.getTime() : this.dialogValObj.startTime
      this.dialogValObj.endTime = typeof this.dialogValObj.endTime == 'object' ? this.dialogValObj.endTime.getTime() : this.dialogValObj.endTime
      this.dialogValObj.delay = this.dialogValObj.delay || 2
      this.dialogValObj.bitrate = this.dialogValObj.bitrate || 5120
      this.dialogValObj.shareIds = this.checkedSocial
      // UGC tpeerid为空，传ugcAccountId， PGC ugcAccountId为空，传tpeerid
      if (this.dialogValObj.eventType == 1) {
        this.dialogValObj.tpeerid = ''
      } else {
        this.dialogValObj.ugcAccountId = ''
      }
      // 编辑及复制时，auto模式，只要修改了channelGroup都需要将rId清空掉
      if (this.dialogValObj.auto == 1 && this.dialogValObj.userGroupId != this.event.userGroupId && this.event.editFlag) {
        this.dialogValObj.rpeerid = ''
      }
      if (!this.addFlag) {
        this.saveLoading = false
        return
      }
      if (!this.dialogValObj.title.trim()) {
        this.$message.error(this.$t('lang.titleEmpty'))
        this.saveLoading = false
        return
      }
      // 验证选中的social账号是否过期
      if (this.dialogValObj.shareIds.length) {
        const expiredArr = await this.checkAccount()
        if (expiredArr.length) {
          let expiredName = []
          expiredArr.forEach(v => {
            const item = this.socialList.filter(m => m.id == v)
            expiredName.push(item[0].nickName)
          })
          this.$message.info(this.$t('lang.accountExpired', { nickName: expiredName.join(',') }))
          this.saveLoading = false
          return
        }
      }
      if (this.event.copyFlag) this.dialogValObj.eventId = ''
      this.addFlag = false
      const url = this.event.editFlag && !this.event.copyFlag ? Outil.getUrl('/event/update') : Outil.getUrl('/event/addEvent')
      this.axios({
        url: url,
        method: 'post',
        data: this.dialogValObj,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        this.saveLoading = false
        this.addFlag = true
        if (res.status == '200') {
          if (res.data.errorCode == '0x0') {
            Bus.$emit('queryBookingList')
            this.handleClose()
          } else {
            const errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
            this.$message.error(errorInfo)
          }
          return
        }
        Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
      }).catch(() => {
        this.saveLoading = false
        this.addFlag = true
      })
    },
    async checkAccount () {
      const socialIds = this.dialogValObj.shareIds
      const expired = []
      for (let i = 0; i < socialIds.length; i++) {
        const params = { mediaId: socialIds[i], flag: true }
        const protocol = window.location.protocol
        const mediaApiUrl = `${protocol}//${this.$store.state.common.baseUrl.mediaApiUrl}`
        await checkSocialAccount(params, mediaApiUrl).then(res => {
          if (res.data.errorCode == '0x0') {
            if (!res.data.result.valid) expired.push(socialIds[i])
          }
        }).catch(() => {})
      }
      return expired
    },
    // 取消创建
    cancel (formName) {
      bookingAnalyze('bookingCreateEventclose', '/bk')
      this.handleClose()
    },
    // 计算live时间
    calcDuration (start, end) {
      if ((!start && start !== 0) || (!end && end !== 0)) {
        this.dialogValObj.duration = null
        return
      }
      if (typeof start == 'object') {
        start = start.getTime()
      }
      if (typeof end == 'object') {
        end = end.getTime()
      }
      this.dialogValObj.duration = (end - start) / 60000 > 0 ? `${Math.floor((end - start) / 60000)}min` : null
    },
    // 初始化搜索条件
    initSearchCondi () {
      this.sourceParam.condition = ''
      this.rParam.condition = ''
      this.sourceParam.pageNum = 1
      this.sourceParam.pageSize = 15
      this.rParam.pageNum = 1
    },
    // 搜索source列表
    searchSource (value, data) {
      this.sourceParam.pageNum = 1
      this.sourceParam.condition = value
      this.getSourceList()
    },
    // 查询source列表
    getSourceList () {
      $('.pick_date_timebk').hide()
      if (this.sourceTotalPag < this.sourceParam.pageNum) {
        return
      }
      this.sourceParam.tokenRegardlessOfUser = this.enableCustomizedCC
      pageUserSource(this.sourceParam).then(res => {
        this.sourceParam.condition = ''
        if (res.status == 200 && res.data.errorCode == '0x0') {
          const result = res.data.result
          this.sourceTotalPag = result.pages || 1
          if (this.sourceParam.pageNum == 1) {
            this.sourceList = result.list
            const temp = filter(this.sourceList, { peerId: this.event.tpeerid })
            if (!temp.length && this.event.editFlag && result.list.length > 0) {
              this.sourceList.push({
                peerId: this.event.tpeerid,
                name: this.event.tname,
                type: this.event.ttype
              })
            }
          } else {
            if (this.event.editFlag) {
              const index = findIndex(result.list, { peerId: this.event.tpeerid })
              if (index >= 0) {
                result.list.splice(index, 1)
              }
            }
            this.sourceList = this.sourceList.concat(result.list)
          }
        } else {
          Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
        }
      }).catch(() => {})
    },
    // 搜索r列表
    searchR (value) {
      this.rParam.pageNum = 1
      this.rParam.condition = value
      this.getRList()
    },
    // 查询R列表 5/6将这里下拉接口改为pageSourceAvailableR,且在切换source时，R下拉框数据需要更新
    getRList () {
      $('.pick_date_timebk').hide()
      if (this.rTotalPag < this.rParam.pageNum) {
        return
      }
      this.rParam.auto = this.dialogValObj.auto
      this.rParam.sid = this.dialogValObj.eventType == 0 ? this.dialogValObj.tpeerid : ''
      this.rParam.userGroupId = this.dialogValObj.userGroupId == null ? '' : this.dialogValObj.userGroupId
      pageBookingChannel(this.rParam).then(res => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          const result = res.data.result
          this.rTotalPag = result.pages || 1
          const seachKey = this.rParam.condition.trim()
          if (result.total == 0 && seachKey == '') {
            this.RList = []
            this.$message.info(this.$t('lang.noPairDestiation'))
            return
          }
          this.rParam.condition = ''
          if (this.rParam.pageNum == 1) {
            this.RList = result.list
            // 这里需要判断，如果编辑数据本身是自动，后台会返回自动R信息，这时切换到手动时，不能将这个R添加到下拉框中
            if (this.event.auto == 0 && this.enableCustomizedCC || !this.enableCustomizedCC) {
              const temp = filter(this.RList, { peerId: this.event.rpeerid })
              if (!temp.length && this.event.editFlag && result.list.length > 0) {
                this.RList.push({
                  peerId: this.event.rpeerid,
                  name: this.event.rname
                })
              }
            }
          } else {
            if (this.event.editFlag) {
              const index = findIndex(result.list, { peerId: this.event.rpeerid })
              if (index >= 0) {
                result.list.splice(index, 1)
              }
            }
            this.RList = this.RList.concat(result.list)
          }
        } else {
          this.rParam.condition = ''
          Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
        }
      }).catch(() => {})
    },
    // change source
    changeSource (val) {
      this.dialogValObj.delay = ''
      this.dialogValObj.bitrate = ''
      this.dialogValObj.rname = ''
      this.dialogValObj.rpeerid = ''
      this.initDestinationStatus()
      this.getRList()
      // 更改create booking中的参数
      for (const v of this.sourceList) {
        if (v.peerId == val) {
          // 切换source之后需要修改source对应的type
          this.dialogValObj.ttype = v.type.toUpperCase()
          this.changeDisableVal(v.type.toLowerCase())
          break
        }
      }
      // 定制化CC里面只有T可以选择自动，非T只能选择Manual
      if (this.dialogValObj.ttype != 'T') {
        this.dialogValObj.auto = 0
      }
    },
    // 是否禁止输入bit和delay
    changeDisableVal (type) {
      switch (type) {
        case 't':
          this.disabled.delay = false
          this.disabled.bitrate = false
          break
        case 'x':
          this.disabled.delay = false
          this.disabled.bitrate = true
          break
        default:
          this.disabled.delay = true
          this.disabled.bitrate = true
          break
      }
    },
    // 切换group
    changeGroup (val) {
      this.initDestinationStatus()
      this.dialogValObj.rname = ''
      this.dialogValObj.rpeerid = ''
    },
    // change r
    changeR (val) {
      this.dialogValObj.rpeerid = val
    },
    updateDelayValue (val) {
      this.dialogValObj.delay = val
    },
    updateBitValue (val, index) {
      this.dialogValObj.bitrate = val
    },
    // 转换splice type
    transformSpliceType (val) {
      switch (val) {
        case '0':
          return 'Start Normal'
        case '1':
          return 'Start Immediate'
        case '2':
          return 'End Normal'
        case '3':
          return 'End Immediate'
        case '4':
          return 'Cancel'
      }
    },
    // 更改创建开始时间
    changeStartTime (val) {
      // 如果开始时间小于当前时间
      if (val.getTime() <= new Date().getTime()) {
        this.dialogValObj.startTime = new Date(new Date().getTime() + 60000)
        // this.$message.error(this.$t('lang.startTimeLessThanCurTime'));
      }
      var endTime = 0
      if (this.dialogValObj.endTime && this.dialogValObj.endTime != '') {
        endTime = typeof this.dialogValObj.endTime == 'number' ? this.dialogValObj.endTime : this.dialogValObj.endTime.getTime()
        // 如果开始时间大于结束时间，改变结束时间
        if (this.dialogValObj.startTime.getTime() >= endTime) {
          this.dialogValObj.endTime = this.dialogValObj.startTime.getTime() + (60 * 60 * 1000)
        }
      }
      this.calcDuration(this.dialogValObj.startTime, this.dialogValObj.endTime)
    },
    // 更改创建结束时间
    changeEndTime (val) {
      const timeStr = typeof val == 'object' ? val.getTime() : val
      // 如果结束时间前于当前时间
      if (timeStr <= new Date().getTime()) {
        this.dialogValObj.endTime = new Date().getTime() + (60 * 60 * 1000)
        // this.$message.error(this.$t('lang.stopTimeLessThanCurTime'));
      }
      var startTime = 0
      if (this.dialogValObj.startTime && this.dialogValObj.startTime != '') {
        startTime = typeof this.dialogValObj.startTime == 'number' ? this.dialogValObj.startTime : this.dialogValObj.startTime.getTime()
      }
      if (this.dialogValObj.endTime <= startTime) {
        this.dialogValObj.endTime = startTime + (60 * 60 * 1000)
      }
      this.calcDuration(this.dialogValObj.startTime, this.dialogValObj.endTime)
    },
    initDestinationStatus () {
      if (this.dialogValObj.tpeerid != '' && this.dialogValObj.eventType == 0 && this.enableCustomizedCC ||
        this.dialogValObj.eventType == 1 && this.enableCustomizedCC ||
        !this.enableCustomizedCC && this.dialogValObj.tpeerid != '') {
        this.allowSelectR = true
      } else {
        this.allowSelectR = false
      }
    }
  },
  filters: {
    // filter name
    filterName (name) {
      if (name && name.length > 50) {
        return `${name.slice(0, 50)}...`
      }
      return name
    }
  },
  beforeDestroy () {
    const nodeList = document.querySelectorAll('.create_box_time')
    nodeList.forEach(v => {
      v.removeEventListener('focus', this.elClick)
    })
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-dialog {
  .el-dialog__header {
    .el-dialog__headerbtn {
      font-size: 20px;
      .el-icon-close,
      .el-icon-close:hover {
        transform: rotate(-45deg);
        font-size: 27px;
        margin-top: 26px;
      }
      .el-icon-close:before {
        content: '\E602';
        color: #fff;
      }
    }
  }
  .el-dialog__body {
    padding: 20px 0 0 !important;
    .create_booking_box {
      .form_content {
        max-height: 600px;
        overflow-y: auto;
        padding: 0 15px;
        .el-form-item {
          margin-bottom: 15px;
          &.is-required:not(.is-no-asterisk) {
            .el-form-item__label:before {
              color: #fff;
              margin-right: 0;
            }
          }
          .el-form-item__content {
            text-align: left;
            .el-input__suffix {
              display: none;
            }
            .el-input__inner,
            .el-textarea__inner,
            .el-input__inner:focus:focus {
              border: none !important;
              background: #444 !important;
            }
            .el-date-picker {
              .el-input__inner,
              .el-input__inner:focus:focus {
              background: #fff!important;
              border: 1px solid #dcdfe6!important;
            }
            }
            .el-select {
              display: block;
              .el_tag.el-tag--info {
                color: #fff !important;
                background: #6b6d6b !important;
              }
              .el-select__input {
                color: #fff;
              }
              .el-input__suffix {
                display: block !important;
              }
              .el-select-dropdown {
                text-align: left;
                width: 487px;
              }
            }
            .el-input__icon.el-icon-time {
              display: none;
            }
            .el-date-editor.el-input {
              width: 150px;
              float: left;
            }
            .el-input--prefix .el-input__inner {
              padding-left: 5px;
              padding-right: 5px;
            }
            .el-radio-group {
              margin-top: 5px;
              .el-radio__input.is-disabled.is-checked .el-radio__inner {
                background-color: #33ab4f;
                background: #33ab4f;
                border-color: #33ab4f;
              }
              .el-radio.is-disabled.is-checked .el-radio__label {
                color: #33ab4f;
              }
            }
            .arrow-icon {
              position: absolute;
              bottom: 18px;
              right: 12px;
              z-index: 10;
            }
            .el-input__icon.el-input__validateIcon.el-icon-circle-check,
            .el-input__icon.el-input__validateIcon.el-icon-circle-close {
              display: none;
            }
          }
        }
        .delay_bit {
          text-align: right;
          .el-form-item__content {
            margin-left: 0 !important;
            .el-input {
              width: 150px;
            }
            .splice_type_box {
              i {
                position: absolute;
                right: 6px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            .splice_type {
              margin-right: -6px;
            }
          }
        }
      }
      .btn_box {
        .el-form-item__content {
          text-align: center;
          margin-left: 0 !important;
          padding: 15px 0;
          .el-button {
            width: 120px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
