<template>
  <div class="calendar pr">
    <div class="device_list">
      <div class="create_info">
        <div
          class="clearfix"
          v-if='creating'>
          <div class="create_info_title f_left">
            <i class='iconfont'>&#xe635;</i>
            <span class="title_text">{{$t('lang.creating')}}:</span>
          </div>
          <div class="create_info_cont f_left">
            <span class='f_left ellipsis'>{{creatingObj.source}}</span>
            <span class='f_left ellipsis'>{{creatingObj.r}}</span>
            <span class='f_left ellipsis'>{{creatingObj.time}}</span>
          </div>
        </div>
      </div>
      <div class="device_content">
        <div
          class="source_list"
          v-loading="loading.source"
          element-loading-background="rgba(0, 0, 0, 0.5)">
          <div class="device_title">
            <span class='f_left'>{{$t('lang.source')}}</span>
            <i
              class="iconfont icon-Filter f_right pointer"
              @click.stop='showEle("filterBox")'></i>
            <i
              class="iconfont icon-zoom f_right pointer"
              @click.stop='showEle("searchS")'></i>
          </div>
          <div
            class="search_ipt"
            v-show='calendar.searchS'
            @click='toggleSource($event)'>
            <input
              type="text"
              class='device_condition'
              v-model="sourcePar.condition"
              :placeholder="$t('lang.searchTText')"
              @keydown="searchSourceCond($event)">
            <i
              class="iconfont icon-zoom f_right pointer"
              @click='getSourceList'></i>
            <i class="down_arrow"></i>
          </div>
          <div
            class="filter_box pr"
            v-show='calendar.filterBox'
            @click='prevent($event)'>
            <div class="filter_item pointer bk_filter_item">
              <div
                class="item_title pr"
                :class='{active: isShow.bookmark}'
                @click='switchShowBF'>
                {{$t('lang.Bookmark')}}({{bookmarkL}})
                <!-- <span class='down_arrow pull_down'></span> -->
                <i class='el-icon-caret-bottom fr'></i>
              </div>

            </div>
            <div
              class="item_content bk_item_content pointer"
              v-show='isShow.bookmark'>
              <!-- <Cascader
                :bookmark='bookmark'
                @changeBookmark='changeBookmark'></Cascader> -->
              <book-mark
                :callback="changeBookmark"
                :from="{Unclassified: true}"
                :defaultKeys="defaultBookmark"
                fromPage="bk"
              ></book-mark>
            </div>
            <div
              class="filter_item pointer"
              style='margin-top:10px'>
              <div
                class="item_title pr"
                :class='{active: !isShow.bookmark}'
                @click='switchShowBF'>
                {{$t('lang.filter')}}({{checkedFilterList.length}})
                <!-- <span class='down_arrow pull_down'></span> -->
                <i class='el-icon-caret-bottom fr'></i>
              </div>
            </div>
            <div
              class="item_content pointer"
              v-show='!isShow.bookmark'>
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange">{{$t('lang.all')}}</el-checkbox>
              <div style="margin: 15px 0;"></div>
              <el-checkbox-group
                v-model="checkedFilterList"
                @change="handleCheckedCitiesChange">
                <el-checkbox
                  v-for="filterItm in filterList"
                  :label="filterItm"
                  :key="filterItm">{{$t(filterItm)}}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div
            class="list_box"
            :style='{height: sListBoxHeight}'
            v-show='isShow.sourceList'>
            <scroll-box
              :deviceList='sourceList'
              :chooseIndex='chooseSIndex'
              @chooseDevice='chooseSource'
              v-scroll='bindSScroll'>
            </scroll-box>
          </div>
        </div>
        <div class="r_list">
          <div class="device_title r_title">
            <span class='f_left'>{{$t('lang.destination')}}</span>
            <i
              class="iconfont icon-zoom f_right pointer"
              @click.stop='showEle("searchR")'></i>
          </div>
          <div
            class="search_ipt"
            v-show='calendar.searchR'
            @click='toggleR($event)'>
            <input
              type="text"
              v-model="rPar.condition"
              :placeholder="$t('lang.searchTText')"
              class='device_condition'
              @keydown="searchRCond($event)">
            <i
              class="iconfont icon-zoom f_right pointer"
              @click='getRList("rList")'></i>
            <i class="down_arrow"></i>
          </div>
          <div
            class="list_box"
            :style='{height: rListBoxHeight}'
            v-show='isShow.rList'>
            <scroll-box
              :deviceList='rList'
              :chooseIndex='chooseRIndex'
              @chooseDevice='chooseR'
              v-scroll='bindRScroll'>
            </scroll-box>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar_box">
      <div class="cal_date_opt f_left">
        <i
          class="iconfont pointer"
          @click='preDate'>&#xe604;</i>
        <el-date-picker
          v-model="dateTitle"
          type="date"
          popper-class='pick_date_time'
          @change='changeDateView'></el-date-picker>
        <i
          class="iconfont pointer"
          @click='nextDate'>&#xe61b;</i>
      </div>
      <full-calendar
        ref="calendar"
        v-if='calendarFlag'
        v-loading='loadingCalendar'
        element-loading-background="rgba(0, 0, 0, .6)"
        :event-sources="eventSources"
        @event-selected="eventSelected"
        @event-created="eventCreated"
        @day-click="dayClick"
        :config="calendarConfig"></full-calendar>
      <div
        class='create_booking triangle'
        v-if='isShow.createBox'
        :style='{left:createBox.left,top:createBox.top,height:createBox.height}'
        :class='{right:createBox.triangle,bottom:createBox.bottom,device:!isShow.note}'
        v-clickoutside='closeCreateBox'
        @click='closeDownList'>
        <div class="create_title clearfix">
          <ul
            class='f_left'
            v-if='!isShow.edit'>
            <li
              class='f_left'
              @click='showCreateBoxCon("note")'
              :class='{active:isShow.note}'>{{$t('lang.note')}}</li>
            <li class='f_left separator'>|</li>
            <li
              class='f_left'
              @click='showCreateBoxCon("device")'
              :class='{active:!isShow.note}'>{{$t('lang.device')}}</li>
          </ul>
          <div v-if='isShow.edit'>
            <p
              class='event_color f_left'
              :class='selColor'></p>
            <p class='event_title f_left ellipsis'>{{eventDetail.title}}</p>
          </div>
          <div
            class="iconfont f_right pointer"
            @click.stop='closeCreateBox'>&#xe603;</div>
        </div>
        <div
          class="note"
          v-if='!isShow.edit && isShow.note'>
          <div class="c_item booking_title">
            <input
              type="text"
              class='f_left'
              maxlength='32'
              v-model="event.title"
              :placeholder="$t('lang.createTitle')"
              :disabled='disable.input'
              :class='{gray:disable.input}'>
            <div
              class="color_box f_right pr pointer"
              @click.stop="showColorOpt">
              <div
                class="color f-left"
                :class='selColor'></div>
              <i
                class="el-icon-caret-bottom"
                :class='{top:isShow.showColorOpt}'></i>
              <div
                class="color_opt triangle"
                v-if='isShow.showColorOpt'>
                <span class='f_left'>{{$t('lang.color')}}</span>
                <ul class='f_left'>
                  <li
                    class="f_left green"
                    @click='chooseColor("green", "rgb(15, 190, 113)")'></li>
                  <li
                    class="f_left skyblue"
                    @click='chooseColor("skyblue", "rgb(70, 201, 225)")'></li>
                  <li
                    class="f_left blue"
                    @click='chooseColor("blue", "rgb(47, 141, 244)")'></li>
                  <li
                    class="f_left orange"
                    @click='chooseColor("orange", "rgb(255, 197, 115)")'></li>
                  <li
                    class="f_left pink"
                    @click='chooseColor("pink", "rgb(255, 106, 146)")'></li>
                  <li
                    class="f_left yellow"
                    @click='chooseColor("yellow", "rgb(255, 192, 0)")'></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="c_item">
            <div class="time_opt start_time f_left">
              <el-date-picker
                v-model="createStartTime"
                type="datetime"
                popper-class='pick_date_time'
                @change='changeStartTime'
                :placeholder='$t("lang.selectData")'
                :picker-options="pickerDateBeg"
                :disabled='disable.input'
                :class='{gray: disable.input}'>
              </el-date-picker>
            </div>
            <div class="f_left to">to</div>
            <div class="time_opt end_time f_right">
              <el-date-picker
                v-model="createStopTime"
                popper-class='pick_date_time'
                :picker-options="pickerDateEnd"
                @change='changeEndTime'
                type="datetime"
                :placeholder='$t("lang.selectData")'>
              </el-date-picker>
            </div>
          </div>
          <div class="c_item">
            <input
              type="text"
              class='reporter'
              maxlength="32"
              :placeholder="$t('lang.reporter')"
              :disabled='disable.input'
              :class='{gray: disable.input}'
              v-model="event.reporter">
          </div>
          <div class="c_item des">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              maxlength="255"
              :placeholder="$t('lang.des')"
              :disabled='disable.input'
              :class='{gray: disable.input}'
              v-model="event.description"></textarea>
          </div>
        </div>
        <div
          class="device"
          v-if='!isShow.edit && !isShow.note'>
          <div class="d_item source clearfix">
            <span class='f_left'>{{$t('lang.source')}}</span>
            <span class='f_right val ellipsis'>{{event.source}}</span>
          </div>
          <div class="d_item clearfix pr">
            <span class='f_left'>{{$t('lang.destination')}}</span>
            <!-- <span class='f_right val pointer'
                            @click.stop='showCreBoxRList'
                            :class='{gray:disable.input,triangle:!disable.input}'
                        > -->
            <span
              class='f_right val pointer ellipsis'
              @click.stop='showCreBoxRList'
              :class='{gray:disable.input,triangle:!disable.input,up:isShow.createBoxRList}'>{{event.destination}}
              <div
                class='createbox_r_list'
                :class='{show_height:isShow.showHeight}'
                v-if='isShow.createBoxRList'>
                <!-- {{createRList}} -->
                <scroll-box
                  :deviceList='createRList'
                  @chooseDevice='selR'
                  v-scroll='bindCreBoxRScroll'></scroll-box>
              </div>
            </span>
          </div>
          <div class="d_item clearfix">
            <span class='f_left'>{{$t('lang.delay')}}(0.5-50s)</span>
            <input
              type="text"
              class='delay'
              maxlength="32"
              v-if='isShow.editDelay'
              v-model='event.delay'
              :disabled='disable.input'
              :class='{gray:disable.input,active:heavyLoadObj.active.delay}'
              @focus="heavyLoadObj.change('delay', true)"
              @blur="heavyLoadObj.change('delay', false, 0.5, 50)">
            <span
              class='f_right val'
              v-else>/</span>
          </div>
          <div class="d_item clearfix">
            <span class='f_left'>{{$t('lang.bit')}}(100-10240Kbps)</span>
            <input
              type="text"
              maxlength="32"
              class='bitrate'
              v-if='isShow.editBit'
              v-model='event.bitrate'
              :disabled='disable.input'
              :class='{gray: disable.input,active:heavyLoadObj.active.bitrate}'
              @focus="heavyLoadObj.change('bitrate', true)"
              @blur="heavyLoadObj.change('bitrate', false, 100, 10240)">
            <span
              class='f_right val'
              v-if='!isShow.editBit'>/</span>
          </div>
        </div>
        <div
          class="event_content_detail"
          v-if='isShow.edit'>
          <p class='detail_item clearfix'>
            <span class='f_left'>{{$t('lang.time')}}</span>
            <span class='f_right ellipsis'>{{eventDetail.time}}</span>
          </p>
          <p class='detail_item clearfix'>
            <span class='f_left'>{{$t('lang.reporter')}}</span>
            <span
              class='f_right ellipsis'
              style='width: 260px'>{{eventDetail.reporter}}</span>
          </p>
          <p class='detail_item clearfix'>
            <span class='f_left'>{{$t('lang.storySlug')}}</span>
            <span
              class='f_right ellipsis'
              style='width: 260px'>{{eventDetail.description}}</span>
          </p>
          <p class='detail_item clearfix'>
            <span class='f_left'>{{$t('lang.source')}}</span>
            <span class='f_right ellipsis'>{{eventDetail.source}}</span>
          </p>
          <p class='detail_item clearfix'>
            <span class='f_left'>{{$t('lang.destination')}}</span>
            <span class='f_right ellipsis'>{{eventDetail.r}}</span>
          </p>
        </div>
        <div class="create_footer clearfix">
          <button
            class='save f_right'
            v-show='!isShow.edit'
            v-repeat-click='{event:saveAddBooking}'>{{$t('lang.save')}}</button>
          <button
            class='cancel f_right'
            v-show='!isShow.edit'
            @click='closeCreateBox'>{{$t('lang.cancel')}}</button>
          <button
            class='delete f_left'
            v-show='isShow.edit&&isShow.deleteBtn&&curLiveStatus!=5'
            @click='deleteEvent'>{{$t('lang.delete')}}</button>
          <button
            class='copy f_left'
            v-show='isShow.edit&&isShow.deleteBtn&&curLiveStatus!=5'
            @click='copyEvent'>{{$t('lang.copy')}}</button>
          <button
            class='end_live f_left'
            v-show='isShow.edit&&!isShow.deleteBtn'
            @click='endLivingEvent'>{{$t('lang.goodNight')}}</button>
          <button
            class='edit f_right'
            v-show='isShow.edit'
            @click='editEvent'>{{$t('lang.edit')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Outil from '@/assets/js/utils.js'
import CreatingInfo from '@/assets/js/bokigCommon.js'
import BookMark from '@/components/bookMark'
import scrollBox from './scrollBox'
import { bookingAnalyze } from '@/assets/js/googleAnalyze/booking.js'
import toTips from '@/assets/js/toTipsObj'
import Bus from '@/assets/js/vueBus.js'
import { FullCalendar } from 'vue-full-calendar'
import 'fullcalendar/dist/fullcalendar.css'

export default {
  components: {
    BookMark,
    scrollBox,
    FullCalendar
  },
  props: ['bookmark'],
  data () {
    return {
      defaultBookmark: {
        bookmardIds: ['all'],
        token: true,
        unClassified: true
      },
      bindSScroll: { // source绑定滚动事件
        onScroll: this.handleSourceScroll,
        distance: 10,
        directive: '.scroll_box ul'
      },
      bindRScroll: { // r绑定滚动事件
        onScroll: this.handleRScroll,
        distance: 10,
        directive: '.scroll_box ul'
      },
      bindCreBoxRScroll: { // 创建框中的r绑定滚动事件
        onScroll: this.handleCreBoxRScroll,
        distance: 10,
        directive: '.scroll_box ul'
      },
      dateInfo: {
        show: false,
        date: {
          start: '',
          end: '',
          date: [],
          weekTitle: []
        }
      },
      loadingCalendar: false,
      calendarFlag: true,
      calendarHeight: '',
      curLiveStatus: null,
      event: {
        title: '',
        source: '',
        rname: '',
        tname: '',
        description: '',
        delay: '',
        bit: '',
        reporter: '',
        startTime: '',
        stopTime: '',
        color: 'rgb(15, 190, 113)',
        rpeerid: '',
        tpeerid: '',
        ttype: '',
        turl: '',
        eventId: '',
        duration: ''
      },
      deleteId: '',
      loading: {
        source: false
      },
      addBookingParam: {
        title: '',
        rpeerid: '',
        tpeerid: '',
        startTime: '',
        endTime: '',
        description: '',
        delay: '',
        reporter: '',
        color: '',
        ttype: '',
        turl: '',
        bitrate: '',
        tname: ''
      },
      calendar: {
        searchS: false,
        searchR: false,
        filterBox: false
      },
      pickerDateBeg: {
        disabledDate (time) {
          var date = Outil.getDateTime()
          return time.getTime() < new Date(date.year + '-' + date.month + '-' + date.day + ' 00:00:00').getTime()
        }
      },
      pickerDateEnd: {
        disabledDate (time) {
          var date = Outil.getDateTime()
          return time.getTime() < new Date(date.year + '-' + date.month + '-' + date.day + ' 00:00:00').getTime()
        }
      },
      filterOptions: ['lang.tvuPack', 'lang.tvuAnywhere', 'lang.gridPack', 'lang.gridSDI', 'Ext', 'token'],
      filterList: [],
      checkAll: true,
      checkedFilterList: ['lang.tvuPack', 'lang.tvuAnywhere', 'lang.gridPack', 'lang.gridSDI', 'Ext', 'token'],
      isIndeterminate: false,
      createBox: { // 创建框的位置
        left: 0,
        top: 0,
        triangle: false,
        bottom: false,
        height: '3rem'
      },
      createStartTime: '',
      createStopTime: '',
      startDate: '',
      dateTitle: '', // 日历头部时间title
      events: [],
      eventFrom: '',
      calendarConfig: { // full-calendar的配置参数
        buttonText: {
          today: this.$t('lang.todayKey'),
          month: this.$t('lang.monthKey'),
          week: this.$t('lang.weekKey'),
          day: this.$t('lang.dayKey')
        },
        header: {
          left: '',
          center: 'prev,title,next'
        },
        navLinks: true,
        selectable: true,
        dayRender: (date, cell) => {
        },
        firstDay: new Date().getDay(), // 日历从哪一天开始
        columnFormat: 'ddd M-D',
        // slotDuration: '00:00:10',
        defaultView: 'agendaWeek',
        allDaySlot: false,
        firstHour: 1,
        slotEventOverlap: false, // 事件不可重叠
        editable: false, // 事件不可拖动缩放
        eventLimit: true, // allow "more" link when too many events
        borderColor: '#333',
        // height: 902,
        contentHeight: 800,
        selectHelper: true,
        unselectAuto: false, // 设置当点击页面其他地方的时候，是否清除已选择的区域，值为布尔类型
        loading: (isLoading, view) => {
          if ($('.calendar_box .el-date-editor.el-date-editor--date').find('.replace_title').length == 0) $('.calendar_box .el-input__inner').after('<p class="replace_title"></p>')
          $('.el-input__inner').css({
            border: 'none',
            backgroundColor: 'transparent'
          })
          // 阻止冒泡
          $('.fc-body').on('click', (e) => {
            e.stopPropagation()
          })
          // 显示时间标题
          this.changeCalTitle()
          if (!isLoading) {
            var time = view.calendar.currentDate._d
            this.dateTitle = time.getFullYear() + '-' + Outil.timeFormat(time.getMonth() + 1) + '-' + Outil.timeFormat(time.getDate())
            // 切换日历视图
            $('.fc-button-group button').on('click', () => {
              this.changeCalTitle()
              // 阻止冒泡
              $('.fc-body').on('click', (e) => { e.stopPropagation() })
            })
            $('.fc-toolbar.fc-header-toolbar .fc-button-group button').click(() => { this.dragToCreateEvent() })
          }
          // 添加下划线
          $('.cal_date_opt .el-input__inner').on('mouseenter', () => {
            $('.replace_title').css('text-decoration', 'underline')
          })
          $('.cal_date_opt .el-input__inner').on('mouseleave', () => {
            $('.replace_title').css('text-decoration', 'none')
          })
        },
        viewRender: (view, element) => { // 渲染视图后
          const title = $('.fc-toolbar .fc-center h2').html()
          $('.cal-header .cal-date').html(title)
          // 更新模式选择
          const lis = $('.cal-type li')
          lis.map((item, idx) => {
            if ($(item).attr('data-type') === view.type) {
              $(item).addClass('active').siblings('li').removeClass('active')
            }
          })
          setTimeout(() => {
            this.addCopyIcon()
            $('.fc-more').click(() => {
              this.addCopyIcon('.fc-popover.fc-more-popover')
            })
          })
        },
        // 点击被创建的事件
        eventClick: (event, jsEvent, view) => {
          const start = Outil.getDateTime(event.start._d)
          const stop = Outil.getDateTime(event.end._d)
          this.isShow.edit = true
          this.jsEventObj = jsEvent.currentTarget
          const calendarScrollTop = $('.fc-scroller.fc-time-grid-container').scrollTop() || 0
          const mainScrollTop = $('.el-main').scrollTop() || 0
          if (this.eventFrom == 'copyIcon') {
            this.isShow.edit = false
          } else {
            this.isShow.create = false
          }
          this.eventFrom = ''
          let position
          if (start.year == stop.year && start.month == stop.month && start.day == stop.day) {

          } else {
            position = {
              left: jsEvent.clientX - jsEvent.offsetX,
              // top: jsEvent.clientY - 210 + calendarScrollTop + (jsEvent.clientY * 2 > $('.fc-view-container').height() + mainScrollTop ? 0 : mainScrollTop),    // 220为所有头部的高度和+三角的高度
              top: jsEvent.clientY - 210 + calendarScrollTop + (jsEvent.clientY * 2 + calendarScrollTop + mainScrollTop > $('.fc-view-container').height() ? mainScrollTop : 0), // 210为所有头部的高度和+三角的高度; +-20为三角高度
              bottom: jsEvent.clientY * 2 + calendarScrollTop + mainScrollTop > $('.fc-view-container').height()
            }
          }
          this.fillCreateBox(event) // 编辑框内的数据
          this.setCreateBox(event.start._d, event.end._d, position)
          // bookingAnalyze(`bookingCalendarEventDetailBoxOpen`, '/bk');
        },
        select: (start, end, event, view, resource) => {
          if (this.isShow.createBox) { // 点击日历，隐藏创建框
            $('.create_booking').click()
          }
          if (this.chooseRIndex != null && this.chooseSIndex != null) { // 是否有选中的R和源
            var id = new Date().getTime()
            var UTCStartTime = Outil.getUTCTime(start._d)
            var UTCEndTime = Outil.getUTCTime(end._d)
            this.clearDataAndUpdate(new Date(UTCStartTime), new Date(UTCEndTime), id)
            $('a.fc-time-grid-event.fc-event.fc-helper').addClass('antline')
            return
          }
          $('#calendar').fullCalendar('unselect')
        },
        selectAllow: (selectInfo) => { // 自定义可选取范围
          const date = selectInfo.start._d
          // var UTCStartTime = date.getTime() + date.getTimezoneOffset() * 60000;
          const UTCStartTime = Outil.getUTCTime(date)
          if (UTCStartTime < new Date()) {
            if (this.isShow.createBox) { // 不取消上次选中时间
              $('#calendar').fullCalendar('select', this.startDate, this.endDate)
            }
            return false
          } else {
            return true
          }
        },
        dayClick: (event, jsEvent, view) => {
          jsEvent.stopPropagation()
          this.selected = event
        }
      },
      selected: {},
      sourcePar: { // 获取源列表的参数
        condition: '',
        bookmardIds: 'all',
        unClassified: true,
        pack: true,
        anywhere: true,
        gridPack: true,
        gridSdi: true,
        ext: true,
        token: true,
        scte: false,
        pageNum: 1,
        pageSize: 35,
        booking: true
      },
      sourceList: [], // 源列表
      totalPage: { // 设备的总页数
        source: 1,
        r: 1
      },
      lastCondition: { // 最后一次搜索的条件
        source: '',
        r: ''
      },
      rPar: { // 获取R列表的参数
        condition: '',
        tpeerId: '',
        bookmardIds: 'all',
        unClassified: true,
        pageNum: 1,
        pageSize: 35,
        booking: true
      },
      lastLeftRPageNum: 1,
      lastrightRPageNum: 1,
      rList: [],
      chooseSIndex: 0, // 选中的源下标
      chooseRIndex: null, // 选中的R下标
      creating: false, // 正在创建的左头部Booking信息
      creatingInfo: new CreatingInfo([]),
      creatingObj: { // 创建中的booking信息
        source: '',
        r: '',
        time: ''
      },
      isShow: {
        showColorOpt: false,
        createBox: false,
        note: true,
        showHeight: false,
        createBoxRList: false,
        bookmark: true,
        edit: true,
        editDelay: false,
        editBit: false,
        deleteBtn: true,
        rList: true,
        sourceList: true
      },
      isCopy: false,
      disable: {
        input: false
      },
      magnifyingBtns: 50,
      selColor: 'green',
      addFlag: true,
      createRList: [], // 创建框中的R列表
      jsEventObj: '',
      bookmarkArr: [],
      selBookmarkLength: 0,
      eventDetail: { // 点击booking后的展示框内数据
        title: '',
        time: '',
        reporter: '',
        description: '',
        source: '',
        r: ''
      },
      heavyLoadObj: {
        active: {
          delay: false,
          bitrate: false
        }
      }
    }
  },
  created () {
    this.filterList = this.filterOptions // filter选项初始化
    this.getSourceList()
    this.createStartTime = new Date()
    this.createStopTime = new Date()
    window.addEventListener('resize', this.handleResize)
    this.getBookmarkChooseVal(this.bookmark, true)
    this.selBookmarkLength = this.bookmarkArr.length
    // this.handleResize();
    Outil.heavyLoad(this.heavyLoadObj, 'change', 2, this.changeActiveStatus, this)
    Outil.heavyLoad(this.heavyLoadObj, 'change', 4, this.inputScope, this)
    Bus.$on('langSeltSwitch', (val) => {
      this.closeCreateBox()
      if (!this.$i18n) {
        return
      }
      this.calendarConfig.buttonText = {
        today: this.$t('lang.todayKey'),
        month: this.$t('lang.monthKey'),
        week: this.$t('lang.weekKey'),
        day: this.$t('lang.dayKey')
      }
      this.calendarFlag = false
      this.handleResize()
      this.$nextTick(() => {
        this.calendarFlag = true
        this.getBookingEventAccodT()
      })
    })
  },
  watch: {
    createStartTime (val) {
      if (val) this.event.startTime = val.getTime()
    },
    createStopTime (val) {
      if (val) this.event.stopTime = val.getTime()
    },
    sourceList () {
      this.$nextTick(() => {
        this.dragToCreateEvent()
      })
    },
    'dateInfo.show' (val) {
      this.$nextTick(() => {
        if (!val) return
        this.dragToCreateEvent({ name: 'magnify' })
      })
    }
  },
  methods: {
    // 元素绑定拖拽事件
    dragToCreateEvent (viewObj) {
      const view = viewObj || $('#calendar').fullCalendar('getView')
      let target
      const sourceList = document.querySelectorAll('.source_list .scroll_box li')
      let dragElement = null
      const targetX = $('#calendar').offset().left
      let timeArr = []
      this.dateInfo.date.weekTitle = [undefined]
      for (let i = 0; i < sourceList.length; i++) {
        // 拖拽开始的时候触发
        sourceList[i].ondragstart = function () {
          dragElement = this
        }
      }
      switch (view.name) {
        case 'month':
          target = document.querySelector('.fc-body .fc-day-grid.fc-unselectable')
          break
        case 'agendaWeek':
          target = document.querySelector('.fc-slats table tbody')
          break
        case 'agendaDay':
          target = document.querySelector('.fc-slats table tbody')
          break
        case 'magnify':
          target = document.querySelector('.magnify .content .tbody')
          break
      }
      if (view.name == 'agendaWeek' || view.name == 'magnify') {
        const timeEle = $('.fc-head-container .fc-day-header')
        timeArr = []
        timeEle.map((i, v) => {
          timeArr.push($(v).attr('data-date'))
          this.dateInfo.date.weekTitle.push($(v).find('a').html())
        })
        this.dateInfo.date.date = timeArr
      }
      if (target) {
        target.ondragover = function (e) {
          e = e || event
          // 阻止默认事件
          e.preventDefault()
        }
        // drop事件默认是触发不了的，需要在dragover事件里面阻止默认事件
        target.ondrop = (e) => {
          e = e || event
          let startDate
          let stopDate
          let distance
          let index
          let date
          let position = $(e.target).offset()
          switch (view.name) {
            case 'month': { // 月视图
              index = $(e.target).parent().find('td').index($(e.target)) - $(e.target).parent().find('.fc-more-cell').length
              const parent = $(e.target).parents('.fc-row.fc-week.fc-widget-content.fc-rigid')
              const targetEle = parent.find(`.fc-day:eq(${index})`)
              startDate = `${$(targetEle).attr('data-date').replace(/-/g, '/')} 00:00:00`
              const stop = Outil.getDateTime(new Date(new Date(startDate).getTime() + (24 * 60 * 60 * 1000)))
              stopDate = `${stop.year}/${stop.month}/${stop.day} ${stop.hour}:${stop.min}:${stop.s}`
              distance = e.clientX - targetX
              position = $(e.target).offset()
              position.rowIndex = $('.fc-row.fc-week.fc-widget-content.fc-rigid').index(parent)
              break
            }
            case 'agendaWeek': { // 周视图
              const startTime = $(e.target).parent().attr('data-time')
              const stopTime = startTime.split(':')[1] == '30' ? `${Number(startTime.split(':')[0]) + 1}:00:00` : `${startTime.split(':')[0]}:30:00`
              distance = e.clientX - targetX - 39
              index = Math.floor(distance / (($(e.target).width() - 2) / 7).toFixed(2))
              date = timeArr[index].replace(/-/g, '/')
              startDate = `${date} ${startTime}`
              stopDate = `${date} ${stopTime}`
              break
            }
            case 'agendaDay': { // 日视图
              distance = e.clientX - targetX - 39
              date = $('.fc-day-header.fc-widget-header').attr('data-date').replace(/-/g, '/')
              const start = $(e.target).parent().attr('data-time')
              startDate = `${date} ${start}`
              const time = Outil.getDateTime(new Date(new Date(startDate).getTime() + (30 * 60 * 1000)))
              stopDate = `${time.year}/${time.month}/${time.day} ${time.hour}:${time.min}:${time.s}`
              break
            }
            case 'magnify': { // 放大视图
              startDate = `${$(e.target).attr('data-date')} ${$(e.target).attr('data-time')}`
              stopDate = new Date(startDate).getTime() + 1000
              break
            }
          }
          // 小于当前时间不可创建
          if (new Date(startDate).getTime() <= new Date()) {
            this.$message.error(this.$t('lang.startTimeLessThanCurTime'))
            return
          }
          // 拖拽时更新头部r信息
          this.creatingInfo.update({
            recvs: [],
            rpeerId: ''
          })
          // 更改源
          this.chooseSource(JSON.parse(dragElement.dataset.device), Number(dragElement.dataset.index))
          if (view.name != 'magnify') {
            if (view.name != 'agendaDay') {
              position.left += Number((($(e.target).width() - 2) / 7).toFixed(2)) * index
            } else {
              position.left += 10
            }
          }
          const calendarScrollTop = $('.fc-scroller.fc-time-grid-container').scrollTop()
          position.top = position.top - 210 + calendarScrollTop
          this.event.destination = ''
          this.event.rpeerid = ''
          this.clearDataAndUpdate(new Date(startDate), new Date(stopDate), new Date().getTime(), position)
        }
      }
    },
    /*
    * 清空数据和更新信息
    * clearDataAndUpdate(startDate, stopDate, start, end, id, position) {
    */
    clearDataAndUpdate (start, end, id, position) {
      // 设置创建框d
      this.isShow.edit = false
      this.jsEventObj = ''
      this.event.title = ''
      this.event.eventId = ''
      this.event.bitrate = ''
      this.event.delay = ''
      this.event.reporter = ''
      this.event.description = ''
      this.upeHeaderCreateBookTime(start, end, id) // 更新页面头部左侧创建事件的信息
      this.setCreateBox(start, end, position)
      this.isShow.createBox = true
      this.changeBookingParam(this.sourceList[this.chooseSIndex])
      if (position && position.important) { // zoom窗口中的打开创建框不需发送谷歌分析
        return
      }
      bookingAnalyze('bookingCalendarCreateBoxOpen', '/bk')
    },
    // 窗口大小调整
    handleResize () {
      // this.calendarFlag = false;
      this.isShow.createBox = false
      if (document.body.clientWidth > 1024) {
        this.isShow.rList = true
        this.isShow.sourceList = true
        this.calendar.searchS = false
        this.calendar.searchR = false
      } else {
        this.isShow.rList = false
        this.isShow.sourceList = false
        this.calendar.searchS = true
        this.calendar.searchR = true
      }
      this.creating = false
    },
    handleSourceScroll () { // source滚动到底部处理事件
      if (this.totalPage.source < this.sourcePar.pageNum) {
        return
      }
      this.sourcePar.pageNum++
      this.getSourceList()
    },
    handleRScroll () { // r滚动到底部处理事件
      this.rPar.pageNum = this.lastLeftRPageNum
      if (this.totalPage.r < this.rPar.pageNum) {
        return
      }
      this.rPar.pageNum++
      this.lastLeftRPageNum = this.rPar.pageNum
      this.getRList('rList')
    },
    handleCreBoxRScroll () { // 创建框中滚动到底部处理事件
      this.rPar.pageNum = this.lastrightRPageNum
      if (this.totalPage.r < this.rPar.pageNum) {
        return
      }
      this.rPar.pageNum++
      this.lastrightRPageNum = this.rPar.pageNum
      this.getRList('createRList')
    },
    // 切换输入框
    changeActiveStatus (curKey, value) {
      for (const key in this.heavyLoadObj.active) {
        if (key == curKey) {
          this.heavyLoadObj.active[key] = value
        } else {
          if (this.event[key]) this.heavyLoadObj.active[key] = false
          else this.heavyLoadObj.active[key] = true
        }
      }
    },
    // 延迟和比特率输入的范围
    inputScope (curKey, value, min, max) {
      this.changeActiveStatus(curKey, value)
      if (this.event[curKey]) {
        this.event[curKey] = this.event[curKey].toString().replace(/[^\d.]/g, '')
        this.event[curKey] = this.event[curKey] < min ? min : this.event[curKey]
        this.event[curKey] = this.event[curKey] > max ? max : this.event[curKey]
        this.event[curKey] = this.event[curKey].toString()
      }
      if (this.event[curKey] && this.event[curKey].includes('.')) {
        const val = this.event[curKey].split('.')
        this.event[curKey] = `${val[0]}.${val[1]}`
        this.event[curKey] = Number(val[1]) ? this.event[curKey] : this.event[curKey].split('.')[0]
      }
    },
    // 显示编辑框
    editEvent () {
      bookingAnalyze('bookingCalendarEventEditOpen', '/bk')
      this.isShow.edit = false
    },
    copyEvent () {
      this.editEvent()
      this.isCopy = true
      this.isShow.create = true
    },
    // 编辑booking事件
    fillCreateBox (event) {
      this.isShow.deleteBtn = true
      this.curLiveStatus = null
      const titleArr = event.title.split(' ') // 更改编辑框内的title
      titleArr.splice(0, 1)
      this.event.title = titleArr.join('')
      this.chooseColor(event.colorName, event.backgroundColor, false) // 颜色
      this.createStartTime = event.start._d // 开始时间
      this.createStopTime = event.end._d // 结束时间
      var otherInfo = event.id.split('&')
      var infoObj = {}
      for (var i = 0; i < otherInfo.length; i++) {
        var arr = otherInfo[i].split('=')
        infoObj[arr[0]] = arr[1]
      }
      this.event.reporter = infoObj.reporter
      this.event.description = infoObj.description
      this.event.source = infoObj.tname
      this.event.destination = infoObj.rname
      this.event.delay = infoObj.delay
      this.event.bitrate = infoObj.bitrate
      // 能否设置delay和bitrate
      this.changeInputEditVal(infoObj)
      // 更改展示框内的内容
      this.eventDetail.title = titleArr.join('')
      this.eventDetail.reporter = infoObj.reporter
      this.eventDetail.description = infoObj.description
      this.eventDetail.source = infoObj.tname
      this.eventDetail.r = infoObj.rname
      var startDate = Outil.getDateTime(event.start._d)
      var endDate = Outil.getDateTime(event.end._d)
      var startDay = startDate.year + '-' + startDate.month + '-' + startDate.day
      var endDay = endDate.year + '-' + endDate.month + '-' + endDate.day
      if (startDay == endDay) {
        this.eventDetail.time = `${startDay} ${startDate.hour}:${startDate.min}:${startDate.s} - ${endDate.hour}:${endDate.min}:${endDate.s}`
      } else {
        this.eventDetail.time = `${startDay} ${startDate.hour}:${startDate.min} - ${endDay} ${endDate.hour}:${endDate.min}`
      }
      this.event.rpeerid = infoObj.rpeerid
      this.event.destination = infoObj.rname
      this.event.eventId = infoObj.eventId
      this.deleteId = infoObj.eventId // 保存点击的事件id
      // 如果已经开始live了
      if (event.start._d.getTime() <= new Date().getTime()) {
        this.eventStatus(infoObj.eventId)
      } else {
        this.isShow.createBox = true
      }
    },
    changeInputEditVal (infoObj, type) {
      this.heavyLoadObj.active.delay = false
      this.heavyLoadObj.active.bitrate = false
      if (infoObj.type.toLowerCase() == 't') {
        this.isShow.editDelay = true
        this.isShow.editBit = true
        if (type == 'create') {
          this.heavyLoadObj.active.delay = true
          this.heavyLoadObj.active.bitrate = true
        }
      } else if (infoObj.type.toLowerCase() == 'x') {
        this.isShow.editDelay = true
        this.isShow.editBit = false
        if (type == 'create') {
          this.heavyLoadObj.active.delay = true
          this.heavyLoadObj.active.bitrate = false
        }
      } else {
        this.isShow.editDelay = false
        this.isShow.editBit = false
      }
    },
    // 判断事件状态
    eventStatus (eventId) {
      this.axios({
        method: 'post',
        url: Outil.getUrl('/event/getEventById'),
        data: { eventId: eventId, booking: true },
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          const result = res.data.result
          if (result.liveStatus == 1 || result.liveStatus == 5) { // running
            if (result.liveStatus == 1) {
              this.isShow.deleteBtn = false
            }
            this.curLiveStatus = result.liveStatus
            this.changeInputStatus(true)
            this.isShow.createBox = true
          } else if (result.liveStatus === 2 || result.liveStatus === 4) {
            this.removeCalendarEvent(eventId)
          }
        }
      }).catch(() => {})
    },
    // 移除已经结束的事件
    removeCalendarEvent (eventId) {
      $('#calendar').fullCalendar('removeEvents', eventId)
      this.$message.error(this.$t('lang.invalidEvent'))
    },
    // 更改输入状态
    changeInputStatus (status) {
      this.disable.input = status
    },
    // 结束live
    endLivingEvent () {
      bookingAnalyze('bookingCalendarEventLiveEnd', '/bk')
      this.$confirm(this.$t('lang.sureDeleteEvent'), '', {
        confirmButtonText: this.$t('lang.Ok'),
        cancelButtonText: this.$t('lang.cancel'),
        type: ''
      }).then(() => {
        this.axios({
          method: 'post',
          url: Outil.getUrl('/event/endEventById'),
          data: { eventId: this.deleteId, booking: true },
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }).then(res => {
          if (res.status == 200) {
            if (res.data.errorCode == '0x0') {
              $('#calendar').fullCalendar('removeEvents', this.deleteId)
              this.isShow.createBox = false
            } else {
              const errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
              this.$message.error(errorInfo)
            }
          }
        }).catch(() => {})
      }).catch(() => {})
    },
    // 设置创建框
    setCreateBox (startDate, endDate, position) {
      this.startDate = startDate
      this.createStartTime = startDate
      this.createStopTime = endDate
      this.endDate = endDate
      const view = $('#calendar').fullCalendar('getView')
      const fifthDay = $('.fc-row.fc-widget-header thead tr>th').eq(5).attr('data-date')
      const createEventPos = position || this.getEventPro() // 获取创建事件的位置
      this.initCreateBox() // 初始化创建框
      this.getCreateBoxTop(position) // 设置创建框的位置
      this.changeInputStatus(false) // 可以输入title，时间等信息
      const deviceLeftWidth = document.body.clientWidth > 1024 ? $('.device_list').width() : 0
      if (view.name == 'month') {
        if (startDate.getDay() > 0 && startDate.getDay() < 5) {
          this.createBox.left = (createEventPos.left - 320 - deviceLeftWidth) / 100 + 'rem'
          this.createBox.triangle = true
        } else {
          this.createBox.left = (createEventPos.left - 40 - deviceLeftWidth) / 100 + 'rem'
          this.createBox.triangle = false
        }
      } else {
        if ((startDate.getTime() + 8 * 60 * 60 * 1000) >= new Date(fifthDay).getTime()) {
          this.createBox.left = (createEventPos.left - 320 - deviceLeftWidth) / 100 + 'rem'
          this.createBox.triangle = true
        } else {
          this.createBox.left = (createEventPos.left - 40 - deviceLeftWidth) / 100 + 'rem'
          this.createBox.triangle = false
        }
      }
      // 获取创建框内的R列表
      this.rPar.pageNum = 1
      this.lastrightRPageNum = 1
      this.getRList('createRList')
      // 禁止里层滚动条滚动
      $('.fc-scroller.fc-time-grid-container').css('overflow-y', 'hidden')
    },

    // 更改创建开始时间
    changeStartTime (val) {
      // 如果开始时间前于当前时间
      if (val.getTime() <= new Date().getTime()) {
        this.createStartTime = new Date(new Date().getTime() + 60000)
        this.$message.error(this.$t('lang.startTimeMoreCurTime'))
      }
      // 如果开始时间大于结束时间，改变结束时间
      var duration = ''
      if (this.createStopTime) {
        duration = (this.createStopTime.getTime() - val.getTime()) / 60000
      }
      if (duration < 0) {
        this.createStopTime = new Date(val.getTime() + 3600000)
      }
      // 持续时间
      if (this.createStopTime) this.duration = (this.createStopTime.getTime() - val.getTime()) / 60000 + 'min'
      if (this.event.eventId) { // 如果是更新事件不显示创建头
        return
      }
      // 更新头部的创建时间
      this.upeHeaderCreateBookTime($.fullCalendar.moment(val), $.fullCalendar.moment(this.createStopTime), this.creatingInfo.temId)
    },
    // 更改创建结束时间
    changeEndTime (val) {
      // 如果结束时间前于当前时间
      let errorMsg = ''
      if (val.getTime() < new Date().getTime()) {
        this.createStopTime = new Date(typeof this.createStartTime == 'object' ? this.createStartTime.getTime() : this.createStartTime + 60000)
        errorMsg = this.$t('lang.endTimeMoreCurTime')
      }
      var duration = ''
      if (this.createStartTime) {
        duration = (val.getTime() - this.createStartTime.getTime()) / 60000
      }
      if (duration < 0) {
        this.createStopTime = new Date(this.createStartTime.getTime() + 3600000)
        errorMsg = this.$t('lang.endTimeMoreStartTime')
      }
      if (errorMsg) {
        this.$message.error(errorMsg)
      }
      // 持续时间
      if (this.createStartTime) this.duration = (this.createStopTime.getTime() - this.createStartTime.getTime()) / 60000 + 'min'
      if (this.event.eventId) { // 如果是更新事件不显示创建头
        return
      }
      // 更新头部的创建时间
      this.upeHeaderCreateBookTime($.fullCalendar.moment(this.createStartTime), $.fullCalendar.moment(val), this.creatingInfo.temId)
    },
    // 处理filter多选框
    handleCheckAllChange (val) {
      this.checkedFilterList = val ? this.filterOptions : []
      this.isIndeterminate = false
      this.initSourcePar(val)
      this.sourcePar.pageNum = 1
      this.getSourceList()
    },
    handleCheckedCitiesChange (value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.filterList.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.filterList.length
      this.changeFilterCondition(value)
    },
    // 更改获取源的参数
    changeFilterCondition (chooseArr) {
      this.initSourcePar(false)
      if (chooseArr.includes('lang.tvuPack')) this.sourcePar.pack = true
      if (chooseArr.includes('lang.tvuAnywhere')) this.sourcePar.anywhere = true
      if (chooseArr.includes('lang.gridPack')) this.sourcePar.gridPack = true
      if (chooseArr.includes('lang.gridSDI')) this.sourcePar.gridSdi = true
      if (chooseArr.includes('Ext')) this.sourcePar.ext = true
      if (chooseArr.includes('token')) this.sourcePar.token = true
      this.sourcePar.pageNum = 1
      this.getSourceList()
    },
    initSourcePar (val) {
      this.sourcePar.pack = val
      this.sourcePar.anywhere = val
      this.sourcePar.gridPack = val
      this.sourcePar.gridSdi = val
      this.sourcePar.ext = val
      this.sourcePar.token = val
    },
    // 关闭创建box
    closeCreateBox (isClick) {
      if (isClick) {
        const googleAnalyzeEvent = this.dateInfo.show ? 'bookingZoomCreatedBoxClose' : 'bookingCalendarEventEditClose'
        bookingAnalyze(googleAnalyzeEvent, '/bk')
      }
      this.creating = false
      $('#calendar').fullCalendar('unselect') // 清空选中区域
      this.isShow.createBox = false
      this.editStatus = {}
      $('.fc-scroller.fc-time-grid-container').css('overflow-y', 'scroll') // 开启里层滚动条滚动
      // 初始化颜色选择框
      this.selColor = 'green'
      this.event.color = 'rgb(15, 190, 113)'
      this.isCopy = false
    },
    changeCalTitle () { // 更改日历title
      var title = $('.fc-center h2').html()
      $('.replace_title').html(title)
    },
    preDate () { // 上一视图日历
      bookingAnalyze('bookingDatePreClick', '/bk')
      $('#calendar').fullCalendar('prev')
      this.changeCalTitle()
    },
    nextDate () { // 下一视图日历
      bookingAnalyze('bookingDateNextClick', '/bk')
      $('#calendar').fullCalendar('next')
      this.changeCalTitle()
    },
    refreshEvents () {
      this.$refs.calendar.$emit('refetch-events')
    },
    removeEvent () {
      this.$refs.calendar.$emit('remove-event', this.selected)
      this.selected = {}
    },
    eventSelected (event) {
      this.selected = event
    },
    eventCreated () {
      this.config.selectable = false
    },
    dayClick (event, jsEvent, view) {
      jsEvent.stopPropagation()
    },
    // 显示对应的元素
    showEle (keyVal) {
      if (keyVal == 'filterBox') {
        bookingAnalyze('bookingCalendarFilterIconClick', '/bk')
        if (document.body.clientWidth <= 1024) {
          this.isShow.sourceList = false
        }
      }
      if (keyVal == 'searchS') {
        bookingAnalyze('bookingSourceConditionClick', '/bk')
      }
      if (keyVal == 'searchR') {
        bookingAnalyze('bookingRConditionClick', '/bk')
      }
      for (var key in this.calendar) {
        if (keyVal == key) {
          this.calendar[key] = !this.calendar[key]
        } else {
          this.calendar[key] = false
        }
      }
      if (document.body.clientWidth <= 1024) {
        this.calendar.searchS = true
        this.calendar.searchR = true
      }
    },
    searchSourceCond (e) {
      if (e.keyCode == 13) this.getSourceList()
    },
    searchRCond (e) {
      if (e.keyCode == 13) {
        this.getRList('rList')
      }
    },
    // 获取源列表
    getSourceList () {
      if (this.lastCondition.source != this.sourcePar.condition) {
        this.lastCondition.source = this.sourcePar.condition
        this.sourcePar.pageNum = 1
      }
      if (this.totalPage.source < this.sourcePar.pageNum) {
        return
      }
      this.loading.source = true
      this.axios({
        method: 'post',
        url: Outil.getUrl('/event/listSource'),
        data: this.sourcePar,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        this.loading.source = false
        if (res.status == 200) {
          if (res.data.errorCode == '0x0') {
            const inputBox = document.getElementsByClassName('device_condition')[0]
            if (inputBox) inputBox.blur()
            if (!res.data.result || !res.data.result.deviceList) return
            this.totalPage.source = res.data.result.deviceList.result.pages || 1
            const data = res.data.result.deviceList.result.list
            if (this.sourcePar.pageNum == 1) {
              this.sourceList = data
            } else {
              data.map((v, i) => {
                this.sourceList.push(v)
              })
            }
            if (!this.sourceList.length) {
              this.chooseSIndex = null
              return
            }
            if (data.length <= 0 || this.sourcePar.pageNum != 1) return
            const item = this.sourceList[this.chooseSIndex || 0]
            this.rPar.tpeerId = item.peerId // 定义默认获取R列表参数T的peerId
            this.getRList('rList') // 获取R列表
            // 更改添加booking参数中的数据
            this.changeBookingParam(item)
            // 根据T获取预约事件
            this.getBookingEventAccodT(item.peerId)
          } else {
            this.$message.error(res.data.errorInfo)
          }
        }
      }).catch(() => {
        this.loading.source = false
      })
    },
    // 更改添加booking参数中的数据
    changeBookingParam (item) {
      this.event.tpeerid = item.peerId
      this.event.ttype = item.type
      this.event.turl = item.url
      this.event.source = item.name
      this.changeInputEditVal(item, 'create')
    },
    // 获取R列表
    getRList (rList) {
      if (this.lastCondition.r != this.rPar.condition) { // 搜索条件更改时
        this.lastCondition.r = this.rPar.condition
        this.rPar.pageNum = 1
        this.lastLeftRPageNum = 1
      }
      if (this.totalPage.r < this.rPar.pageNum) { // 搜索的页面不能超过总页数
        return
      }
      this.axios({
        method: 'post',
        url: Outil.getUrl('/event/listSourceR'),
        data: this.rPar,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          const inputBox = document.getElementsByClassName('device_condition')[0]
          if (inputBox) inputBox.blur()
          this.totalPage.r = res.data.result.pages || 1
          const data = res.data.result.list
          if (this.rPar.pageNum == 1) {
            this[rList] = data
            return
          }
          data.map((v, i) => {
            this[rList].push(v)
          })
        }
      }).catch(() => {})
    },
    transformTime (time) {
      const endTime = time + 30
      const start = `${Math.floor(time / 60) > 9 ? '' : '0'}${Math.floor(time / 60)}:${time % 60 > 9 ? '' : '0'}${time % 60}`
      const end = `${Math.floor(endTime / 60)}:${endTime % 60}`
      this.dateInfo.date.start = start
      this.dateInfo.date.end = end
    },
    // 选择源
    chooseSource (item, index) {
      this.creating = false // 隐藏创建booking信息展示
      this.chooseSIndex = index // 让点击的T选中
      this.chooseRIndex = null
      this.rPar.tpeerId = item.peerId // 更改获取R列表的参数T的peerId
      this.getRList('rList')
      this.getBookingEventAccodT(item.peerId)
      this.changeBookingParam(item) // 更改添加booking的参数
      // 更改创建booking中的source info
      this.updateCreateSource(item)
    },
    // 选择R
    chooseR (item, index) {
      this.chooseRIndex = index
      this.event.rpeerid = item.peerId
      this.event.destination = item.name
      // 更改创建booking中的R info
      this.creatingInfo.update({
        recvs: [item.name],
        rpeerId: item.peerId
      })
    },
    // 更改创建booking中的source info
    updateCreateSource (item) {
      this.creatingInfo.update({
        source: item.name,
        tpeerId: item.peerId,
        url: item.url,
        type: item.type
      })
    },
    // 更新创建booking头部信息的时间
    upeHeaderCreateBookTime (start, end, id) {
      this.creatingInfo.update({
        mstart: start,
        mend: end,
        start: new Date(start),
        end: new Date(end),
        temId: id,
        firstClick: false
      }) // 更新页面头部左侧创建事件的信息
      this.showCreateBoxInfo() // 显示左侧头部的创建信息
    },
    // show create box info in header
    showCreateBoxInfo () {
      this.creatingObj.source = this.creatingInfo.source
      this.creatingObj.r = this.creatingInfo.recvs.join(', ')
      if (this.creatingInfo.start && this.creatingInfo.end) {
        var startDate = new Date(this.creatingInfo.start)
        var endDate = new Date(this.creatingInfo.end)
        if (startDate.getDate() == endDate.getDate()) {
          var timeStr = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate() + ' ' + Outil.timeFormat(startDate.getHours()) + ':' + Outil.timeFormat(startDate.getMinutes()) + ' - ' + Outil.timeFormat(endDate.getHours()) + ':' + Outil.timeFormat(endDate.getMinutes())
        } else {
          timeStr = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate() + ' ' + Outil.timeFormat(startDate.getHours()) + ':' + Outil.timeFormat(startDate.getMinutes()) + ' - ' + endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate() + ' ' + Outil.timeFormat(endDate.getHours()) + ':' + Outil.timeFormat(endDate.getMinutes())
        }
        this.creatingObj.time = timeStr
      }
      this.creating = true
    },
    // close down list
    closeDownList () {
      this.isShow.showColorOpt = false
    },
    // show color box option
    showColorOpt () {
      this.isShow.showColorOpt = !this.isShow.showColorOpt
      const googleAnalyzeEvent = this.dateInfo.show ? 'bookingZoomColorOpen' : 'bookingEventColorOpen'
      if (!this.isShow.showColorOpt) return
      bookingAnalyze(googleAnalyzeEvent, '/bk')
    },
    // 选择颜色
    chooseColor (color, val, googleAny) {
      this.selColor = color
      this.event.color = val
      const googleAnalyzeEvent = this.dateInfo.show ? 'bookingZoomColorChange' : 'bookingEventColorChange'
      if (googleAny === false) return
      bookingAnalyze(googleAnalyzeEvent, '/bk')
    },
    // 创建框显示内容
    showCreateBoxCon (item) {
      if (item == 'note') {
        this.createBox.height = '3rem'
        this.isShow.note = true
        this.isShow.createBoxRList = false
        this.createBox.top = this.createBox.lastTop
      } else {
        this.createBox.height = '2.6rem'
        this.createBox.lastTop = this.createBox.top
        // this.createBox.top = `${Number(this.createBox.top.slice(0, this.createBox.top.indexOf('rem'))) + 0.3}rem`;
        if (this.createBox.bottom) {
          this.createBox.top = `${Number(this.createBox.top.slice(0, this.createBox.top.indexOf('rem'))) + 0.3}rem`
        }
        this.isShow.note = false
        this.isShow.createBoxRList = false
      }
      // this.getCreateBoxTop();
    },
    // 设置创建框的位置
    getCreateBoxTop (position) {
      const createEventPos = position || this.getEventPro()
      if (!createEventPos) return
      if ($('.fc-highlight').length > 0) {
        createEventPos.rowIndex = $('.fc-highlight').parents('.fc-row.fc-week.fc-widget-content.fc-rigid').index()
      }
      const calendarScrollTop = $('.fc-scroller.fc-time-grid-container').scrollTop() || 0
      if (($('#calendar').fullCalendar('getView').name != 'month' && this.startDate.getHours() >= 12 && (this.endDate.getHours() >= this.startDate.getHours() || this.endDate.getHours() == 0)) || (createEventPos.rowIndex && createEventPos.rowIndex >= 4) || createEventPos.bottom) {
        const boxHeight = 230 // 因为创建框朝下，230为创建框盒子高度-日历头部-三角部分距离
        this.createBox.top = (createEventPos.top - calendarScrollTop - boxHeight) / 100
        this.createBox.top = this.createBox.top < 0 ? 0 : `${this.createBox.top}rem`
        this.createBox.bottom = true
      } else {
        const boxHeight = 96 // 96为切换视图栏+日历title栏+三角部分距离
        this.createBox.top = (createEventPos.top + boxHeight - calendarScrollTop) / 100 + 'rem'
        this.createBox.bottom = false
      }
    },
    // 获取事件的位置
    getEventPro () {
      const view = $('#calendar').fullCalendar('getView')
      let offset, position
      if (view.name == 'month') {
        position = this.jsEventObj ? $(this.jsEventObj).offset() : $('.fc-highlight-skeleton .fc-highlight').offset()
        // position.top = this.jsEventObj ? position.top - 20 : position.top;
        if (position.left == 0 && position.top == 0) {
          position = $(this.jsEventObj).parent().siblings('.fc-more-cell').offset()
        }
        position.top = position.top - 210
      } else {
        offset = this.jsEventObj ? $(this.jsEventObj).offset() : $('.fc-time-grid-event.fc-helper').offset()
        position = this.jsEventObj ? $(this.jsEventObj).position() : $('.fc-time-grid-event.fc-helper').position()
        position.left += offset.left
        if ($(this.jsEventObj).siblings('a.fc-event').length > 0) {
          position.left = offset.left
        }
      }
      return position
    },
    // 初始化创建框
    initCreateBox () {
      this.isShow.note = true
      this.createBox.height = '3rem'
    },
    // 选择R
    selR (itm, index) {
      this.chooseRIndex = index
      this.event.destination = itm.name
      this.event.rpeerid = itm.peerId
      // 更改创建booking中的R info
      this.creatingInfo.update({
        recvs: [itm.name],
        rpeerId: itm.peerId
      })
      this.creatingObj.r = this.creatingInfo.recvs.join(', ')
    },
    prevent (e) {
      e.stopPropagation()
      this.isShow.createBoxRList = false
    },
    // 是否显示创建框内的R列表
    showCreBoxRList () {
      if (this.disable.input) return // living状态不能修改R
      this.isShow.createBoxRList = !this.isShow.createBoxRList
    },
    // 保存booking事件
    saveAddBooking () {
      let googleAnalyzeEvent = this.dateInfo.show ? 'bookingZoomCreatedEventSave' : 'bookingCalendarEventEditSave'
      bookingAnalyze(googleAnalyzeEvent, '/bk')
      let param = {
        title: this.event.title.trim(),
        color: this.event.color,
        reporter: this.event.reporter,
        description: this.event.description,
        startTime: this.event.startTime,
        endTime: this.event.stopTime,
        rpeerid: this.event.rpeerid,
        tpeerid: this.event.tpeerid,
        ttype: this.event.ttype,
        tname: this.event.source,
        delay: this.event.delay || 2,
        bitrate: this.event.bitrate || 5120,
        turl: this.event.turl,
        scheduleType: 'live',
        scteId: '',
        duration: '',
        prerollTime: '',
        booking: true
      }
      if (!param.title.trim() && param.title !== 0) {
        this.$message.error(this.$t('lang.titleEmpty'))
        return
      }
      if (!param.rpeerid) {
        this.$message.error(this.$t('lang.rEmpty'))
        return
      }
      let url = '/event/addEvent'
      if (this.event.eventId) {
        if (!this.isCopy) {
          param.eventId = this.event.eventId
        }
        url = this.isCopy ? '/event/addEvent' : '/event/update'
      }
      if (!this.addFlag) return
      this.addFlag = false
      this.axios({
        method: 'post',
        url: Outil.getUrl(url),
        data: param,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        this.addFlag = true
        if (res.status == 200) {
          if (res.data.errorCode == '0x0') {
            this.closeCreateBox() // 关闭弹窗
            // 删除成功后刷新列表
            this.getBookingEventAccodT(this.sourceList[this.chooseSIndex].peerId)
          } else {
            const errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
            this.$message.error(errorInfo)
          }
        }
      }).catch(() => {
        this.addFlag = true
      })
    },
    // 跳转日期
    changeDateView (date) {
      $('#calendar').fullCalendar('gotoDate', date)
    },
    changeBookmark (val) {
      // 书签
      this.sourcePar.pageNum = 1
      this.sourcePar.bookmardIds = val.bookmardIds
      this.sourcePar.unClassified = val.unClassified
      this.getSourceList()
    },
    // 循环遍历bookmark的值
    getBookmarkChooseVal (arr, init) {
      if (arr.length == 0) return
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].check || init) this.bookmarkArr.push(arr[i].id)
        if (arr[i].children.length) this.getBookmarkChooseVal(arr[i].children, init)
      }
    },
    // 切换显示bookmark
    switchShowBF () {
      if (!this.isShow.bookmark) {
        bookingAnalyze('bookingBookmarkClick', '/bk')
      } else {
        bookingAnalyze('bookingfilterClick', '/bk')
      }
      this.isShow.bookmark = !this.isShow.bookmark
    },
    // 获取与T相关的预约事件
    getBookingEventAccodT (tId) {
      this.loadingCalendar = true
      var param = {
        tid: tId || this.sourceList[this.chooseSIndex].peerId,
        rids: [],
        startDate: 0,
        endDate: '15015547523410',
        booking: true
      }
      this.axios({
        method: 'post',
        url: Outil.getUrl('/event/listEventByTR'),
        data: param,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        this.loadingCalendar = false
        $('#calendar').fullCalendar('removeEvents') // 先清空表格
        this.events = []
        let eventDatas = []
        if (res.status != 200 || res.data.errorCode != '0x0') {
          this.$message(this.$t('lang.'))
          return
        }
        const data = res.data.result
        data.map((item, idx) => {
          if (item.liveStatus < 2 || item.liveStatus == 5) { // 过去的事件不再执行
            item.parameters = JSON.parse(item.parameters)
            item.source = JSON.parse(item.source)
            const eventId = 'eventId=' + item.eventId + '&description=' + (item.description || '') + '&reporter=' + (item.parameters.reporter || '') + '&tname=' + item.tname +
              '&rname=' + item.rname + '&tpeerid=' + item.tpeerid + '&rpeerid=' + item.rpeerid + '&type=' + item.ttype + '&delay=' +
              (item.parameters.delay || '') + '&bitrate=' + (item.parameters.bitrate || '') + '&url=' + (item.source.url || '')
            let bgColor = item.color || 'rgb(15, 190, 113)'; let colorName
            switch (bgColor) {
              case 'rgb(255, 192, 0)':
                colorName = 'yellow'
                break
              case 'rgb(70, 201, 225)':
                colorName = 'skyblue'
                break
              case 'rgb(47, 141, 244)':
                colorName = 'blue'
                break
              case 'rgb(255, 197, 115)':
                colorName = 'orange'
                break
              case 'rgb(255, 106, 146)':
                colorName = 'pink'
                break
              default:
                colorName = 'green'
                bgColor = 'rgb(15, 190, 113)'
                break
            }
            const startTime = Outil.getDateTime(new Date(item.startTime))
            const endTime = Outil.getDateTime(new Date(item.endTime))
            const eventData = {
              id: eventId,
              title: `${startTime.hour}:${startTime.min}:${startTime.s}-${endTime.hour}:${endTime.min}:${endTime.s} ${item.title}`,
              start: $.fullCalendar.moment(item.startTime),
              end: $.fullCalendar.moment(item.endTime),
              backgroundColor: bgColor,
              colorName: colorName,
              className: `livestatus-${item.liveStatus}`,
              borderColor: 'transparent'
            }
            eventDatas.push(eventData)
            $('#calendar').fullCalendar('renderEvent', eventData, true) // 渲染到表格
          }
        })
        this.events = eventDatas
        this.addCopyIcon()
        $('.fc-more').click(() => {
          this.addCopyIcon('.fc-popover.fc-more-popover')
        })
      }).catch(() => {
        this.loadingCalendar = false
      })
    },
    // 添加copy icon
    addCopyIcon (fatherEle) {
      fatherEle = fatherEle || ''
      $(`${fatherEle} .fc-widget-content a.fc-event .fc-content`).append(`<i class='iconfont icon-log fr' title='${this.$t('lang.duplicateEvent')}'><i>`)
      $(`${fatherEle} .fc-widget-content a.fc-event.livestatus-1 .fc-content i.icon-log`).remove()
      $(`${fatherEle} .fc-widget-content a.fc-event.livestatus-5 .fc-content i.icon-log`).remove()
      $(`${fatherEle} .fc-widget-content a.fc-event .fc-content .icon-log`).click(() => {
        this.eventFrom = 'copyIcon'
        this.copyEvent()
      })
      const that = this
      $(`${fatherEle} .fc-widget-content a.fc-event .fc-content .icon-log`).mouseenter(function () {
        if (!that.$i18n) {
          return
        }
        this.title = that.$t('lang.duplicateEvent')
      })
    },
    // 删除booking
    deleteEvent () {
      bookingAnalyze('bookingCalendarEventDelete', '/bk')
      const deleteIdArr = this.deleteId.split(',')
      this.$confirm(this.$t('lang.deleteBookingPrompt'), '', {
        confirmButtonText: this.$t('lang.btn'),
        cancelButtonText: this.$t('lang.cancel'),
        type: ''
      }).then(() => {
        this.axios({
          method: 'post',
          url: Outil.getUrl('/event/delete'),
          data: { ids: deleteIdArr, booking: true },
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }).then(res => {
          if (res.data.errorCode == '0x0') {
            this.$message({
              message: this.$t('lang.success'),
              type: 'success'
            })
            this.isShow.createBox = false
            // 删除成功后刷新列表
            this.getBookingEventAccodT(this.sourceList[this.chooseSIndex].peerId)
          } else {
            let errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
            this.$message.error(errorInfo)
          }
        }).catch(() => {})
      }).catch(() => {})
    },
    // 小屏下切换source列表是否显示
    toggleSource (e) {
      if (document.body.clientWidth > 1024) return
      e.stopPropagation()
      this.isShow.sourceList = !this.isShow.sourceList
    },
    // 小屏下切换r列表是否显示
    toggleR (e) {
      if (document.body.clientWidth > 1024) return
      e.stopPropagation()
      this.isShow.rList = !this.isShow.rList
    }
  },
  computed: {
    bookmarkL () {
      return this.$store.state.common.bkBookmarkL
    },
    // 日历渲染
    eventSources () {
      const self = this
      return [
        {
          events (start, end, timezone, callback) {
            self.$nextTick(() => {
              callback(self.events.filter(v => {
                // console.log(v);
              }))
            })
          }
        }
      ]
    },
    // 计算source列表高度
    sListBoxHeight () {
      if (this.calendar.searchS) {
        return 'calc(100% - 100px)'
      }
      if (this.calendar.filterBox) {
        return 'calc(100% - 410px)'
      }
      return 'calc(100% - 50px)'
    },
    // 计算r列表高度
    rListBoxHeight () {
      if (this.calendar.searchR) {
        return 'calc(100% - 100px)'
      }
      return 'calc(100% - 50px)'
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>
<style lang='less' scoped>
@import '~fullcalendar/dist/fullcalendar.css';
::v-deep ::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
::v-deep ::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  background-color: rgb(233, 233, 233);
  opacity: 0.7;
}
::v-deep ::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 5px;
  background: none;
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0; /*高宽分别对应横竖滚动条的尺寸*/
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  background-color: rgb(233, 233, 233);
  opacity: 0.7;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 5px;
  background: none;
}
input {
  outline: none;
}
.show_height {
  height: 170px;
}
ul {
  margin-bottom: 0;
}
.green {
  border: 1px solid #0a9a5a;
  background: #0fbe71;
}
.skyblue {
  background-color: #46c9e1;
  border: 1px solid #0ca595;
}
.yellow {
  background-color: rgb(255, 192, 0);
  border: 1px solid rgb(255, 192, 0);
}
.blue {
  background-color: #2f8df4;
  border: 1px solid #126ed3;
}
.orange {
  background-color: #ffc573;
  border: 1px solid #d8a966;
}
.pink {
  background-color: #ff6a92;
  border: 1px solid #ee3656;
}
.calendar {
  // height: 100%;
  height: 850px;
  .gray {
    color: #666 !important;
    input {
      color: #666 !important;
    }
  }
  .device_list {
    position: absolute;
    // width: 520px;
    width: 600px;
    height: 100%;
    float: left;
    // margin-bottom: 30px;
    background: #444;
    .r_title {
      padding-right: 30px;
    }
    .create_info {
      width: calc(100% - 35px);
      font-size: 10px;
      text-align: left;
      height: 50px;
      margin-left: 35px;
      line-height: 48px;
      .iconfont {
        font-size: 15px;
      }
      .title_text {
        margin: 0 5px;
        color: #999;
      }
      .create_info_cont {
        width: calc(100% - 75px);
        span {
          width: 30%;
          padding: 0 5px;
          line-height: 50px;
        }
      }
    }
    .device_content {
      height: calc(100% - 50px);
      border-bottom: 1px solid #000;
      border-top: 1px solid #000;
    }
    .source_list,
    .r_list {
      width: 50%;
      height: 100%;
      border-right: 1px solid #000;
      font-size: 18px;
      text-align: left;
      box-sizing: border-box;
      float: left;
    }
    // .r_list {
    //     // border: none;
    // }
    .device_title {
      height: 50px;
      line-height: 50px;
      background-color: #555;
      padding-left: 20px;
      padding-right: 10px;
      i {
        font-size: 23px;
      }
      .icon-Filter {
        font-size: 20px;
        margin: 0 10px;
        padding-left: 10px;
      }
    }
    .search_ipt {
      height: 50px;
      line-height: 50px;
      background: #aaa;
      color: #eee;
      outline: none;
      input {
        height: 50px;
        background: transparent;
        border: none;
        width: 80%;
        padding-left: 14px;
        font-size: 16px;
      }
      i {
        font-size: 23px;
        margin-right: 5%;
      }
      i.down_arrow {
        display: none;
      }
    }
    .filter_box {
      height: 360px;
      background: #aaa;
      padding: 10px 0;
      ::v-deep .el-checkbox .el-checkbox__inner {
        background: #fff;
      }
      .filter_item {
        margin: 0 auto;
        width: 90%;
        height: unset;
        background: #444;
        border-radius: 5px;
        .item_title {
          border-radius: 5px;
          height: 45px;
          line-height: 45px;
          padding-left: 10px;
          background: #616161;
          color: #aaa;
          font-size: 14px;
        }
        .item_title.active {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          // .el-icon-caret-bottom {
          //     transform: rotate(-180deg);
          // }
        }
        &.bk_filter_item {
          position: relative;
          z-index: 3;
        }
        // .pull_down {
        //     position: absolute;
        //     right: 15px;
        //     top: 50%;
        //     transform: translateY(-50%);
        // }
        .el-icon-caret-bottom {
          color: #fff;
          font-size: 22px;
          line-height: 45px;
          margin-right: 15px;
          transition: 0.3s;
        }
      }
      .item_content {
        margin: 0 auto;
        width: 90%;
        height: 235px;
        line-height: 30px;
        background-color: #444;
        // margin-bottom: 10px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 12px;
        &.bk_item_content {
          margin-top: -45px;
          position: relative;
          z-index: 2;
          height: 280px;
          border-radius: 5px;
        }
      }
    }
    ::v-deep .list_box {
      .antline {
        position: relative;
      }
    }
  }
  .calendar_box {
    position: absolute;
    right: 0px;
    left: 600px;
    height: 100%;
    background: #444;
    ::v-deep .fc-more-popover {
      max-height: 200px;
      overflow: scroll;
    }
    ::v-deep .fc-unthemed {
      th,
      td,
      thead,
      tbody,
      .fc-divider,
      .fc-row,
      .fc-content,
      .fc-popover,
      .fc-list-view,
      .fc-list-heading td {
        border-color: #333;
      }
    }
    ::v-deep .fc-toolbar.fc-header-toolbar {
      padding-right: 80px;
      margin-bottom: 0;
      height: 50px;
    }
    ::v-deep .fc-unthemed .fc-head-container.fc-widget-header {
      border-top-color: #000;
    }
    ::v-deep .fc-right .fc-button-group {
      width: 230px;
      height: 28px;
      line-height: 28px;
      border-radius: 4px;
      overflow: hidden;
      background-color: #666;
      text-align: center;
      cursor: pointer;
      margin-top: 10px;
      button {
        float: left;
        width: 33.3%;
        height: 100%;
        border-right: 1px solid #999;
        background: #666;
        color: #fff;
        border: none;
        text-shadow: none;
      }
      .fc-state-active {
        background: #999;
        border-top: none;
      }
    }
    ::v-deep .fc-toolbar.fc-header-toolbar {
      padding-right: 80px;
      .fc-center {
        position: relative;
        width: 250px;
        > div {
          width: 100%;
          h2 {
            text-align: center;
          }
          button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: #444;
            background-image: none;
            box-shadow: none;
            text-shadow: none;
            border: none;
            .fc-icon {
              font-size: 17px;
              color: #fff;
              font-weight: normal;
            }
          }
          .fc-prev-button {
            left: 0;
          }
          .fc-next-button {
            right: 0;
          }
        }
      }
    }
    .fc-event,
    .fc-event-dot {
      background-color: rgb(15, 190, 113);
      border: none;
    }
    .fc-time-grid-event.fc-short .fc-time span {
      display: inline;
    }
    .fc-time-grid-event.fc-short .fc-time::before,
    .fc-time-grid-event.fc-short .fc-time::after {
      content: '';
    }
  }
  ::v-deep .create_booking {
    position: absolute;
    width: 4rem;
    height: 3rem;
    background-color: #dcdcdc;
    border-radius: 4px;
    z-index: 2030;
    padding: 0.16rem;
    padding-right: 0.2rem;
    color: #999;
    font-size: 0.16rem;
    box-sizing: border-box;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.78);
    .create_title {
      margin-bottom: 15px;
      .separator {
        padding: 0 10px;
      }
      li.active {
        color: #000;
      }
      .event_color {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border: none;
      }
      .event_title {
        text-align: left;
        font-size: 16px;
        color: #000;
        width: 80%;
      }
    }
    .event_content_detail {
      font-size: 12px;
      color: #000;
      .detail_item {
        height: 32px;
        line-height: 32px;
        border-bottom: 1px solid rgba(153, 153, 153, 1);
        padding: 0 10px;
        margin-bottom: 0;
        span:first-child {
          color: #666;
        }
        span:nth-child(2) {
          width: 80%;
          text-align: right;
        }
      }
      margin-bottom: 30px;
    }
    .c_item {
      height: 30px;
      margin-bottom: 10px;
      input {
        width: 310px;
        height: 30px;
        background-color: rgba(228, 228, 228, 1);
        color: #000;
        border: 1px solid rgba(153, 153, 153, 1);
        border-radius: 4px;
        text-indent: 10px;
        font-size: 12px;
        box-sizing: border-box;
      }
      .time_opt {
        width: 170px;
        height: 30px;
        line-height: 30px;
        color: #000;
        background: rgba(228, 228, 228, 1);
        border: 1px solid rgba(153, 153, 153, 1);
        border-radius: 4px;
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 100%;
        }
        input.el-input__inner {
          width: 100%;
          background: transparent;
          padding: 0;
          border: none;
        }
        .el-input__prefix,
        .el-input__suffix-inner {
          display: none;
        }
      }
    }
    .des.c_item {
      height: 60px;
    }
    .note {
      .color_box {
        height: 100%;
        padding-right: 22px;
      }
      .color_opt {
        width: 220px;
        height: 40px;
        background: #fff;
        color: #999;
        border-radius: 4px;
        position: absolute;
        right: -11px;
        top: 31px;
        padding: 10px 0;
        padding-left: 6px;
        font-size: 12px;
        line-height: 20px;
        z-index: 10000;
        li {
          width: 20px;
          height: 20px;
          cursor: pointer;
          margin-left: 10px;
        }
      }
      .color_opt.triangle::before {
        left: 191px;
      }
      .color {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        margin-top: 4px;
        box-sizing: content-box;
      }
      .el-icon-caret-bottom {
        font-size: 20px;
        position: absolute;
        top: 16%;
        right: 0px;
        transform: translateY(-50%);
        transform: rotate(0deg);
        transition: 0.3s;
      }
      .el-icon-caret-bottom.top {
        transform: rotate(-180deg);
      }
      .to {
        color: #000;
        margin-left: 7px;
        line-height: 30px;
        font-size: 12px;
      }
      .reporter,
      textarea {
        width: 367px;
        border: 1px solid rgba(153, 153, 153, 1);
      }
      textarea {
        background: rgba(228, 228, 228, 1);
        border-radius: 4px;
        height: 60px;
        color: #000;
        font-size: 12px;
        line-height: 15.6px;
        padding: 2px 2px 2px 10px;
        box-sizing: border-box;
      }
    }
    .create_footer {
      margin-top: 20px;
      button {
        width: 86px;
        height: 30px;
        background: #999;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 12px;
      }
      .save,
      .edit {
        background-color: #0c6;
        margin-left: 10px;
      }
      .delete {
        width: 72px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid rgba(153, 153, 153, 1);
        background: transparent;
        color: #333;
      }
      .copy {
        margin-left: 100px;
        color: #39aa50;
        border: 1px solid #39aa50;
        background: transparent;
      }
      .end_live {
        background: #f00;
        color: #fff;
      }
    }
    .device {
      color: rgb(102, 102, 102);
      margin: 0 9px;
      .d_item {
        width: 100%;
        height: 32px;
        line-height: 32px;
        border-bottom: 1px solid rgba(153, 153, 153, 1);
        font-size: 12px;
        padding: 0 20px 0 10px;
        .val {
          color: #000;
          width: 60%;
          text-align: right;
        }
        .delay,
        .bitrate {
          float: right;
          height: 25px;
          width: 135px;
          margin-top: 3px;
          margin-right: -20px;
          border: none;
          padding-left: 10px;
          padding-right: 25px;
          box-sizing: border-box;
          color: #333;
          background-color: transparent;
          text-align: right;
        }
        .delay.active,
        .bitrate.active {
          text-align: left;
          background-color: #fff;
        }
        .triangle:before {
          top: 60%;
          left: 95%;
          transform: translateY(-50%);
          border-color: transparent;
          border-top-color: #999;
          border-width: 6px;
          transition: 0.3s;
        }
        .triangle.up:before {
          top: 23%;
          transform: rotate(-180deg);
        }
        .createbox_r_list {
          position: absolute;
          right: 0;
          top: 32px;
          background: #ababab;
          width: 2.2rem;
          height: 170px;
          text-align: left;
          li {
            padding-left: 10px;
            height: 32px;
            line-height: 32px;
            box-sizing: border-box;
            font-size: 12px;
          }
          li:hover {
            background: rgba(0, 208, 105, 0.2);
          }
          .el-scrollbar__thumb {
            background: #fff;
          }
        }
      }
      .d_item.source {
        input {
          float: right;
          border: none;
          height: 25px;
          width: 100px;
          padding-left: 10px;
          padding-right: 25px;
          margin-right: -20px;
          margin-top: 3px;
          box-sizing: content-box;
          color: #000;
        }
      }
    }
  }
  ::v-deep .create_booking.triangle:before {
    border-top-color: #dcdcdc;
    border-right-color: #dcdcdc;
    border-bottom-color: transparent;
    left: 0.6rem;
    top: -0.095rem;
    border-width: 12px;
    box-shadow: 3px -3px 3px rgba(0, 0, 0, 0.38);
    transform: rotate(-45deg);
  }
  ::v-deep .create_booking.right.triangle:before {
    left: 3.4rem;
  }
  ::v-deep .create_booking.bottom.triangle:before {
    top: 2.85rem;
    // border-color: transparent;
    // border-bottom-color: #dcdcdc;
    // border-lrft-color: #dcdcdc;
    transform: rotate(135deg);
  }
  ::v-deep .create_booking.bottom.device.triangle:before {
    top: 2.46rem;
  }
  ::v-deep .el-checkbox .el-checkbox__inner {
    background: transparent;
  }
  ::v-deep .el-checkbox .el-checkbox__inner::after {
    border-color: #33ab4f;
    left: 4px;
    top: 0;
  }
  ::v-deep .el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #fff;
    border-color: transparent;
  }
  ::v-deep .el-checkbox__inner:hover {
    border-color: #fff;
  }
  ::v-deep .el-checkbox__input.is_focus {
    border-color: #fff;
  }
  ::v-deep.el-checkbox .el-checkbox__input.is-checked + .el-checkbox__label {
    font-size: 12px;
  }
  ::v-deep .el-checkbox .el-checkbox__inner {
    width: 15px;
    height: 15px;
  }
  ::v-deep
    .el-checkbox
    .el-checkbox__input.is-indeterminate
    .el-checkbox__inner {
    border-color: transparent;
  }
  ::v-deep .el-checkbox__label {
    font-size: 12px;
  }
  ::v-deep .el-checkbox {
    display: block;
    margin: 0;
    padding-left: 8px;
    height: 24px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  ::v-deep .el-checkbox:hover {
    background-color: rgba(57, 170, 80, 0.25);
  }
  ::v-deep .item_content.pointer div {
    margin: 0 !important;
  }
}
::v-deep .cal_date_opt {
  margin-left: 30%;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  i {
    font-size: 18px;
  }
  .el-input__suffix,
  .el-input__prefix {
    display: none;
  }
  .el-date-editor input {
    color: transparent;
  }
}
::v-deep .replace_title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  font-size: 16px;
  color: #fff;
}
::v-deep .el-input__inner {
  background-color: transparent;
  z-index: 1000;
  position: relative;
  color: transparent;
  cursor: pointer;
}
::v-deep #calendar .fc-center {
  visibility: hidden;
  display: none;
}
::v-deep #calendar .fc-header-toolbar {
  margin-bottom: 0;
}
::v-deep .fc-time-grid-event.fc-helper {
  overflow: visible;
}
::v-deep .el-popper[x-placement^='top'] .popper__arrow:after {
  background: #fff;
}
::v-deep .calendar .calendar_box .fc-unthemed th {
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  background: #555;
  color: #fff;
  font: 400 normal 14px/50px 'Microsoft yahei';
}
::v-deep .fc-axis.fc-widget-header,
::v-deep .fc-axis.fc-time.fc-widget-content {
  width: 39px !important;
  box-sizing: border-box;
}
::v-deep .fc-unthemed td.fc-today {
  background-color: #666;
}
::v-deep .fc-axis.fc-time.fc-widget-content span {
  font-size: 12px;
}
::v-deep .antline {
  // position: relative;
  background-color: #3f5d46 !important;
  padding: 2px;
  background-image: -webkit-linear-gradient(
    -45deg,
    rgba(16, 180, 103, 1) 25%,
    rgba(16, 180, 103, 0) 25%,
    rgba(16, 180, 103, 0) 50%,
    rgba(16, 180, 103, 1) 50%,
    rgba(16, 180, 103, 1) 75%,
    rgba(16, 180, 103, 0) 75%,
    rgba(16, 180, 103, 0)
  );
  background-size: 10px 10px;
  animation: antline 0.8s linear infinite;
  box-sizing: content-box;
}
::v-deep .fc-widget-content a.fc-event .fc-content .fc-title {
  width: 80%;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
::v-deep .antline::after {
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  display: block;
  content: '';
  background-color: #3f5d46;
}
@keyframes antline {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px 5px;
  }
  100% {
    background-position: 0px 10px;
  }
}
@media (max-width: 1660px) {
  ::v-deep .fc-widget-content a.fc-event .fc-content .fc-title {
    width: 80%;
  }
}
@media (max-width: 1415px) {
  .calendar_box .cal_date_opt {
    margin-left: 20%;
  }
}
@media (max-width: 1320px) {
  .calendar.pr .device_list {
    width: 450px;
  }
  // .calendar.pr .magnifying {
  //     width: 50px;
  //     left: 450px;
  //     button {
  //         width: 45px;
  //     }
  // }
  .calendar.pr .calendar_box {
    left: 450px;
  }
}
@media (max-width: 1215px) {
  ::v-deep .fc-widget-content a.fc-event .fc-content .fc-title {
    width: 70%;
  }
  .calendar_box .cal_date_opt {
    margin-left: 10%;
  }
}
@media (max-width: 1140px) {
  .calendar .calendar_box {
    ::v-deep .fc-toolbar.fc-header-toolbar {
      padding-right: 20px;
    }
  }
}
@media (max-width: 1070px) {
  .calendar.pr .device_list {
    width: 350px;
  }
  // .calendar.pr .magnifying {
  //     width: 50px;
  //     left: 350px;
  //     button {
  //         width: 45px;
  //     }
  // }
  .calendar.pr .calendar_box {
    left: 350px;
  }
}
@media (max-width: 1024px) {
  ::v-deep #Booking .header .table_view {
    margin-left: -3px;
  }
  @headerLeft: 30px;
  .calendar.pr {
    .device_list {
      float: none;
      height: 100px;
      width: 100%;
      .create_info {
        display: none;
      }
      .device_content {
        height: 100px;
      }
      .source_list {
        border: none;
        margin: 15px 0;
        height: 70%;
        border-right: 1px solid #555;
        .device_title {
          height: 35px;
          line-height: inherit;
        }
      }
      .search_ipt {
        width: 32%;
        height: 32px;
        line-height: 32px;
        position: absolute;
        left: @headerLeft;
        border-radius: 5px;
        background: #555;
        outline: none;
        input {
          width: 68%;
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          padding-left: 0;
          color: #fff;
        }
        i.icon-zoom {
          float: left;
          margin-left: 5%;
          margin-top: 2px;
          font-size: 16px;
        }
        i.down_arrow {
          display: block;
          float: right;
          margin-right: 3%;
          margin-top: 11px;
          border-top-color: #d8d8d8;
        }
      }
      .device_title {
        width: calc(64% + 30px);
        padding-left: @headerLeft;
        background: #444;
        > .icon-zoom {
          display: none;
        }
        .icon-Filter {
          margin-right: -10px;
        }
      }
      .filter_box {
        position: relative;
        z-index: 1008;
        width: 64%;
        margin-left: @headerLeft;
      }
      .list_box {
        position: absolute;
        top: 87px;
        left: 30px;
        width: 32%;
        height: 300px !important;
        z-index: 1500;
        background: #555;
        border-radius: 7px;
        ::v-deep ul {
          border-radius: 7px;
        }
      }
      .r_list {
        z-index: 0;
        .search_ipt {
          left: calc(50% + @headerLeft);
        }
        .list_box {
          left: calc(50% + 30px);
        }
      }
    }
    // .magnifying {
    //     left: 0;
    //     top: 150px;
    //     height: calc(100% - 70px);
    //     background: #444;
    // }
    .calendar_box {
      left: 50px;
      top: 100px;
      height: calc(100% - 20px);
      .cal_date_opt {
        margin-left: @headerLeft;
      }
    }
  }
}
@media (max-width: 550px) {
  .calendar_box .cal_date_opt {
    ::v-deep .el-date-editor.el-input {
      width: 170px;
    }
    ::v-deep .el-input__inner {
      padding: 0;
    }
  }
}
::v-deep .fc-time-grid-event.fc-v-event.fc-event .fc-content .fc-time,
::v-deep .fc-day-grid-event.fc-h-event.fc-event .fc-content .fc-time {
  display: none;
}
::v-deep
  .fc-time-grid-event.fc-v-event.fc-event.fc-helper.antline
  .fc-content
  .fc-time {
  display: block;
}
::v-deep .fc-widget-content .fc-content .icon-log {
  margin-right: 3px;
  margin-top: 3px;
  display: none;
}
::v-deep .fc-widget-content .fc-content:hover .icon-log {
  display: block;
}
::v-deep a.fc-event.fc-start.fc-end.fc-draggable {
  min-height: 1px;
  .fc-title {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
}
::v-deep .fc-popover .fc-header {
  color: #333;
}
</style>
<style lang="less">
.el-picker-panel.el-date-picker.el-popper.pick_date_time {
  z-index: 2039 !important;
}
</style>
