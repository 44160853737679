<template>
  <div
    class="audio"
    :style='{width:audioOps.width+"px",height:audioOps.height+"px"}'>
    <div
      class="father"
      :style='{width:audioOps.width+"px",height:audioOps.height+"px"}'>
      <div
        class="active"
        :style='{height:audioOps.activeHeight+"px"}'></div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['audioOps'],
  data () {
    return {
    }
  }
}
</script>
<style scoped>
.audio {
  background: url('../assets/img/audioBg.png') repeat-y scroll center 0 transparent;
}
.father {
  position: relative;
}
.active {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: url('../assets/img/audioActive.png') repeat-y scroll center bottom;
  transition: all 0.1s;
}
</style>
