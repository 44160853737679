import { booking } from '@/assets/js/eventAnalyze.config.js'
export function bookingAnalyze (str, path) {
  if (path != '/bk') return
  try {
    let eventName = booking[str]()
    gtag('event', eventName) // 谷歌统计
  } catch (err) {
    console.log('The Google analysis has not been loaded yet')
  }
}
