<template>
  <div class="table_view">
    <div class="page_menu">
      <el-tabs
        v-model="activeName"
        @tab-click="changeTab"
        :stretch="true">
        <el-tab-pane
          :label="$t(val)"
          :name="val"
          v-for="(val, key) in tabList"
          :key="key">
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="filter">
      <div
        class="time_filter"
        v-if='!bookingListParam.status.includes(1)&&!bookingListParam.status.includes(5)&&!bookingListParam.status.includes(6)'>
        <div class='title f_left'>{{$t('lang.bookingFilter')}}: </div>
        <div class='time start_time f_left pr'>
          <el-date-picker
            v-model="startTime"
            popper-class='pick_date_time'
            type="datetime"
            @change='changeStartTime'>
          </el-date-picker>
        </div>
        <div class='interval f_left'></div>
        <div class='time end_time f_left'>
          <el-date-picker
            v-model="stopTime"
            popper-class='pick_date_time'
            type="datetime"
            @change='changeEndTime'>
          </el-date-picker>
        </div>
        <button
          class='apply f_left'
          @click='getList("apply")'>{{$t('lang.apply')}}</button>
      </div>
      <div
        class="page f_right responsive_pagination"
        v-if='total > 0'>
        <Pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :pageSizes="[10, 20, 50, 100]"
          :pageSize="pageSize"
          :total="total"
          layout="prev, pageF, next, pageL, sizes, nowSize, jumper" />
      </div>
      <div class="delete f_right">
        <span
          :class='{active: deleteActive}'
          @click='deleteEvent'>{{$t('lang.delete')}}</span>
      </div>
    </div>
    <div
      class="content"
      v-loading='loading'
      element-loading-background="rgba(0, 0, 0, .6)">
      <div
        class="booking-tabel-section"
        :class="[enableCustomizedCC ? 'width200': 'width250']">
        <el-row
          type="flex"
          class="row-bg content_row content_title">
          <el-col :span="1">
            <div class="grid-content pr">
              <div
                class="select_box"
                :class='{active: allSel}'
                @click='isAllSel'></div>#
            </div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content">{{$t('lang.scheduleTitle')}}</div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content">{{$t('lang.start')}}</div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content">{{$t('lang.end')}}</div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content duration">{{$t('lang.duration')}}</div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content">{{$t('lang.deviceSource')}}</div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content">{{$t('lang.destination')}}</div>
          </el-col>
          <!-- 定制化CC需求 start -->
          <el-col
            :span="2"
            v-if="enableCustomizedCC">
            <div class="grid-content">{{$t('lang.channelGroup')}}</div>
          </el-col>
          <el-col
            :span="2"
            v-if="enableCustomizedCC">
            <div class="grid-content">{{$t('lang.occurType')}}</div>
          </el-col>
          <!-- 定制化CC需求 stop -->
          <el-col :span="2">
            <div class="grid-content bit">{{$t('lang.delayAndBit')}}<br>（s/kbps）</div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content">{{$t('lang.reporter')}}</div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content">{{$t('lang.storySlug')}}</div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content">{{$t('lang.status')}}</div>
          </el-col>
          <el-col
            :span="4"
            v-if='!bookingListParam.status.includes(4)&&!bookingListParam.status.includes(7)'>
            <div class="grid-content">{{$t('lang.operation')}}</div>
          </el-col>
          <el-col
            :span="4"
            v-if='bookingListParam.status.includes(4)||bookingListParam.status.includes(7)'>
            <div class="grid-content">{{$t('lang.log')}}</div>
          </el-col>
        </el-row>
        <div class="row_section">
          <div
            class='content_detail'
            @click="isSelected(index, item)"
            v-for='(item, index) in dataList'
            :key='index'
            :class='{orangeColor: item.liveStatus == 5}'>
            <el-row
              type="flex"
              class="row-bg content_row content_list_item"
              :class='{active: copyItem === index, edit_status: isShow.edit[item.id] && item.liveStatus != 1 && item.liveStatus != 5}'>
              <el-col :span="1">
                <div class="grid-content pr">
                  <div
                    class="select_box"
                    :class='{active: isSelectedList[item.id].selected && isSelectedList[item.id].selected}'></div>
                  {{(currentPage-1)*pageSize+(index+1)}}
                </div>
              </el-col>
              <el-col :span="2">
                <div
                  class="grid-content"
                  v-if='!isShow.edit[item.id] || item.liveStatus == 1 || item.liveStatus == 5'
                  :title='item.title'>{{item.title}}</div>
                <div
                  class="grid-content ipt"
                  v-if='isShow.edit[item.id] && item.liveStatus != 1 && item.liveStatus != 5'
                  @click='prevent($event)'>
                  <input
                    type="text"
                    v-model="item.title"
                    maxlength='32'>
                </div>
              </el-col>
              <el-col
                :span="2"
                @click='prevent($event)'>
                <div
                  class="grid-content start_time"
                  v-if='!isShow.edit[item.id] || item.liveStatus == 1 || item.liveStatus == 5'>{{getListTime(item.startTime)}} <br>{{getListDate(item.startTime)}}</div>
                <div
                  class="grid-content start_time ipt"
                  v-if='isShow.edit[item.id] && item.liveStatus != 1 && item.liveStatus != 5'>
                  <el-date-picker
                    v-model="item.startTime"
                    popper-class='pick_date_time'
                    type="datetime"
                    :picker-options="pickerDateBeg"
                    :placeholder='$t("lang.selectData")'>
                  </el-date-picker>
                </div>
              </el-col>
              <el-col :span="2">
                <div
                  class="grid-content end_time"
                  v-if='!isShow.edit[item.id]'>{{getListTime(item.endTime)}} <br>{{getListDate(item.endTime)}}</div>
                <div
                  class="grid-content end_time ipt"
                  v-if='isShow.edit[item.id]'>
                  <el-date-picker
                    v-model="item.endTime"
                    popper-class='pick_date_time'
                    :picker-options="pickerDateEnd"
                    type="datetime"
                    :placeholder='$t("lang.selectData")'>
                  </el-date-picker>
                </div>
              </el-col>
              <el-col :span="2">
                <div class="grid-content">{{item.startTime | calcDuration(item.endTime, dataList)}}</div>
              </el-col>
              <el-col :span="2">
                <div
                  class="grid-content device_pull_box pr"
                  v-if="!enableCustomizedCC || enableCustomizedCC && item.eventType == 0"
                  :class='{cursor:isShow.edit[item.id]}'>
                  <pull-down-box
                    :name='item.tname'
                    :deviceList='sourceList'
                    :index='index'
                    :disable='isShow.edit[item.id]'
                    :status='item.liveStatus'
                    type='source'
                    @addPageNum='addPageNum'
                    @getDeviceList='getSourceList'
                    @updateDevice='updateDevice'>
                  </pull-down-box>
                </div>
                <div v-else>
                  <div
                    class="grid-content"
                    :title="item.ugcAccountId">{{item.ugcAccountId}}</div>
                </div>
              </el-col>
              <el-col :span="2">
                <div
                  class="grid-content device_pull_box pr"
                  :class='{cursor:isShow.edit[item.id]}'>
                  <pull-down-box
                    :name='item.rname'
                    :deviceList='RList'
                    :index='index'
                    :disable='isShow.edit[item.id]'
                    :status='item.liveStatus'
                    type='r'
                    @addPageNum='addPageNum'
                    @getDeviceList='getRList'
                    @updateDevice='updateDevice'></pull-down-box>
                </div>
              </el-col>
              <!-- 定制化CC需求 start -->
              <el-col
                v-if="enableCustomizedCC"
                :span="2">
                <div class="grid-content">{{item.userGroupName}}</div>
              </el-col>
              <el-col
                v-if="enableCustomizedCC"
                :span="2">
                <div class="grid-content">{{item.eventType | filterEventType}}</div>
              </el-col>
              <!-- 定制化CC需求 stop -->
              <el-col :span="2">
                <div
                  class="grid-content"
                  v-if='!isShow.edit[item.id] || item.liveStatus == 1 || item.liveStatus == 5'>
                  <span :title='item.parameters.delay'>{{item.parameters.delay | removeExcessDecimals}}</span>
                  <span>/</span>
                  <span :title='item.parameters.bitrate'>{{item.parameters.bitrate | removeExcessDecimals}}</span>
                </div>
                <div
                  class="grid-content bit ipt"
                  v-if='isShow.edit[item.id] && item.liveStatus != 1 && item.liveStatus != 5'
                  @click='prevent($event)'>
                  <input
                    type="text"
                    v-model='item.parameters.delay'
                    v-if='isShow.tType[index] == "t" || isShow.tType[index] == "x"'
                    @blur="updateDelayBit(index,'delay',0.5,50)">
                  <span style='margin-right:3px;'>/</span>
                  <input
                    type="text"
                    v-model='item.parameters.bitrate'
                    v-if='isShow.tType[index] == "t"'
                    @blur="updateDelayBit(index,'bitrate',100,10240)">
                </div>
              </el-col>
              <el-col :span="2">
                <div
                  class="grid-content"
                  v-if='!isShow.edit[item.id] || item.liveStatus == 1 || item.liveStatus == 5'
                  :title='item.parameters.reporter'>{{item.parameters.reporter}}</div>
                <div
                  class="grid-content rep ipt"
                  v-if='isShow.edit[item.id] && item.liveStatus != 1 && item.liveStatus != 5'
                  @click='prevent($event)'>
                  <input
                    type="text"
                    v-model='item.parameters.reporter'
                    maxlength='32'>
                </div>
              </el-col>
              <el-col :span="2">
                <div
                  class="grid-content"
                  v-if='!isShow.edit[item.id] || item.liveStatus == 1 || item.liveStatus == 5'
                  :title='item.description'>{{item.description}}</div>
                <div
                  class="grid-content des ipt"
                  v-if='isShow.edit[item.id] && item.liveStatus != 1 && item.liveStatus != 5'
                  @click='prevent($event)'>
                  <input
                    type="text"
                    v-model='item.description'
                    maxlength='255'>
                </div>
              </el-col>
              <el-col :span="2">
                <div class="grid-content">
                  <el-dropdown trigger="click">
                    <span
                      class="el-dropdown-link"
                      :title='$t(transfromEventStatus(item.liveStatus, item.failedCase, true))'>
                      {{$t(transfromEventStatus(item.liveStatus, item.failedCase))}}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>{{$t(transfromEventStatus(item.liveStatus, item.failedCase, true))}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </el-col>
              <el-col
                :span="4"
                v-if='bookingListParam.status.includes(0)&&bookingListParam.status.includes(8)||(bookingListParam.status.includes(1)&&bookingListParam.status.includes(5)&&bookingListParam.status.includes(6))'
                style='color: #fff;'>
                <div
                  class="grid-content"
                  v-if='bookingListParam.status.includes(0) || item.liveStatus == 5'>
                  <button
                    class='cancel'
                    @click.stop='editCancel(index)'
                    v-show='isShow.edit[item.id]'>{{$t('lang.cancel')}}</button>
                  <button
                    v-repeat-click='{event:updateEvent,params:[index]}'
                    v-show='isShow.edit[item.id]'>{{$t('lang.save')}}</button>
                  <button
                    v-if="bookingListParam.status.includes(1)"
                    @click.stop='editEvent(item, index)'
                    v-show='!isShow.edit[item.id]'>{{$t('lang.edit')}}</button>
                  <button
                    v-if="bookingListParam.status.includes(0)"
                    @click.stop='addEditDialog(item, index)'
                    v-show='!isShow.edit[item.id]'>{{$t('lang.edit')}}</button>
                </div>
                <div
                  class="grid-content"
                  v-if='item.liveStatus == 1'>
                  <button
                    class='cancel'
                    @click.stop='editCancel(index)'
                    v-show='isShow.edit[item.id]'>{{$t('lang.cancel')}}</button>
                  <button
                    v-repeat-click='{event:updateEvent,params:[index]}'
                    v-show='isShow.edit[item.id]'>{{$t('lang.save')}}</button>
                  <button
                    @click.stop='goodNight(item.eventId)'
                    v-show='!isShow.edit[item.id]'
                    style='background:#f00;'>{{$t('lang.goodNight')}}</button>
                  <button
                    v-if="bookingListParam.status.includes(1)"
                    @click.stop='editEvent(item, index)'
                    v-show='!isShow.edit[item.id]'>{{$t('lang.edit')}}</button>
                  <button
                    v-if="bookingListParam.status.includes(0)"
                    @click.stop='addEditDialog(item, index)'
                    v-show='!isShow.edit[item.id]'>{{$t('lang.edit')}}</button>
                </div>
              </el-col>
              <el-col
                :span="4"
                v-else
                style='color: #fff;'>
                <div class="grid-content">
                  <span
                    class='test'
                    :title='$t(getLogText(item.failedCase))'
                    v-if='bookingListParam.status.includes(4)'>{{$t(getLogText(item.failedCase))}}</span>
                  <span v-if='bookingListParam.status.includes(2)'>/</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <create-box
      :visible.sync='visible'
      @closeBox='cancelEdit'
      :event='viewData'
      v-if='visible'></create-box>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import pullDownBox from './pullDownBox'
import Outil from '@/assets/js/utils.js'
import { mapState } from 'vuex'
import Bus from '@/assets/js/vueBus.js'
import { bookingAnalyze } from '@/assets/js/googleAnalyze/booking.js'
import toTips from '@/assets/js/toTipsObj'
import createBox from './createBox'
import { pageUserSource } from '@/assets/api/sourceControl.js'

export default {
  props: {
    clickIndex: {
      type: Number,
      default: 0
    },
    runningEvent: {
      type: Number,
      default: 0
    },
    pendingEvent: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Pagination,
    pullDownBox,
    createBox
  },
  data () {
    return {
      loading: false,
      lastPageView: '',
      sourceParam: {
        pageNum: 1,
        pageSize: 15,
        condition: '',
        living: true,
        online: true,
        offline: true,
        pack: true,
        anywhere: true,
        gridPack: true,
        gridSdi: true,
        ext: true,
        scte: false,
        booking: true
      },
      rParam: {
        bookmardIds: 'all', // 获取R列表的参数
        tpeerId: '',
        unClassified: true,
        condition: '',
        pageNum: 1,
        pageSize: 15,
        booking: true
      },
      totalPage: { // 设备列表的总页数
        source: 1,
        r: 1
      },
      startTime: '',
      stopTime: '',
      // 分页值
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dataList: [],
      lastDataList: [], // update前的数据
      runningBooking: 0,
      isSelectedList: {},
      lastSelectList: {
        viewSchedule: [],
        runningBookings: [],
        pastBookings: [],
        cancelledBookings: []
      },
      allSel: false, // 全选
      copyItem: '',
      deleteActive: false, // 是否激活删除按钮
      errorInfo: {
        error: []
      },
      isShow: {
        edit: {},
        RList: [],
        sourceList: [],
        tType: []
      },
      searchRCond: '',
      sourceCond: '',
      RList: [],
      sourceList: [],
      bookingListParam: {
        pageNum: 1,
        pageSize: 10,
        startDate: '1555344000000',
        endDate: '1555948800000',
        status: [0],
        booking: true
      },
      tabList: ['lang.viewSchedule', 'lang.runningBookings', 'lang.pastBookings', 'lang.cancelledBookings'],
      activeName: 'lang.viewSchedule',
      pickerDateBeg: {
        disabledDate (time) {
          const date = Outil.getDateTime()
          return time.getTime() < new Date(date.year + '-' + date.month + '-' + date.day + ' 00:00:00').getTime()
        }
      },
      pickerDateEnd: {
        disabledDate (time) {
          // return time.getTime() < this.item.startTime;
          const date = Outil.getDateTime()
          return time.getTime() < new Date(date.year + '-' + date.month + '-' + date.day + ' 00:00:00').getTime()
        }
      },
      visible: false,
      viewData: {},
      enableCustomizedCC: this.$store.state.common.baseUrl.enableCustomizedCC || false
    }
  },
  created () {
    this.startTime = this.initDate(new Date()) // 初始化筛选开始时间
    this.stopTime = this.initDate(new Date(new Date(this.startTime.replace(/-/g, '/')).getTime() + (7 * 24 * 60 * 60 * 1000))) // 初始化筛选结束时间
    this.initTimeParam() // 初始化时间参数
    this.switchPage('viewSchedule', true)
    // this.getList();    // 获取列表
  },
  mounted () {
    Bus.$on('queryBookingList', data => {
      this.getList()
    })
    const timer = setInterval(() => {
      const elements = document.getElementsByClassName('device_pull_box')
      if (elements.length > 0) {
        this.elementsAddListener(elements)
        clearInterval(timer)
      }
    }, 500)
  },
  watch: {
    clickIndex (val) {
      var index, key
      for (var i = 0; i < this.isShow.RList.length; i++) {
        if (this.isShow.RList[i]) {
          index = i
          key = 'RList'
        }
      }
      for (var j = 0; j < this.isShow.sourceList.length; j++) {
        if (this.isShow.sourceList[j]) {
          index = j
          key = 'sourceList'
        }
      }
      if (key) this.changeIsShow(key, false, index)
    },
    pendingEvent (val) {
      this.addOrRemoveOrangeColor(val)
    }
  },
  computed: mapState({
    State: state => state,
    pageRequest: state => state.websocket.pageRequest
  }),
  methods: {
    cancelEdit () {
      this.visible = false
    },
    addEditDialog (event, index) {
      this.visible = true
      this.viewData = event
      this.viewData.dialogTitle = 'editOrder'
      this.viewData.editFlag = true
    },
    // 给element ui禁止的click添加click事件
    elementsAddListener (elements) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', () => {
          const index = Math.floor(i / 2)
          this.isSelected(index, this.dataList[index])
        }, true)
      }
    },
    // 添加或移除颜色
    addOrRemoveOrangeColor (val) {
      if (!val) {
        $('.el-tabs__nav div[aria-controls="pane-lang.runningBookings"]').removeClass('orangeColor')
        return
      }
      $('.el-tabs__nav div[aria-controls="pane-lang.runningBookings"]').addClass('orangeColor')
    },
    // 滚动到底部
    addPageNum (type, index) {
      if (this.totalPage[type] < this[`${type}Param`].pageNum) return
      this[`${type}Param`].pageNum++
      type = type.replace(/^\S/, s => s.toUpperCase())
      this[`get${type}List`](undefined, index)
    },
    // 发送获取running页面的list列表
    sendRunningList () {
      const pageRunnEventParam = {
        currentPageNum: '1',
        everyPageNum: '10',
        booking: true,
        module: 'pageRunningEventPage',
        type: 'pageRunningEventPage',
        data: ''
      }
      this.pageRequest.send({
        message: pageRunnEventParam,
        time: 3000,
        key: 'pageRunningEventPage',
        success: data => {
          let flag = true
          const result = JSON.parse(data.result.runningEvent)
          for (const key in this.isShow.edit) {
            if (this.isShow.edit[key]) {
              flag = false
            }
          }
          if (flag) {
            this.getListCallback(result)
          }
        }
      })
    },
    // 发送请求获取有没有pending的事件
    prevent (e) {
      e.stopPropagation()
    },
    // 初始化时间参数
    initTimeParam () {
      const startTime = typeof this.startTime == 'string' ? this.startTime.replace(/-/g, '/') : this.startTime
      const stopTime = typeof this.stopTime == 'string' ? this.stopTime.replace(/-/g, '/') : this.stopTime
      this.bookingListParam.startDate = new Date(startTime).getTime()
      this.bookingListParam.endDate = new Date(stopTime).getTime()
    },
    // 更改开始时间参数
    changeStartTime (time) {
      this.changeBookingListPar('startDate', time.getTime())
    },
    // 更改结束时间参数
    changeEndTime (time) {
      this.changeBookingListPar('endDate', time.getTime())
    },
    // 更改获取booking列表参数
    changeBookingListPar (key, val) {
      if (val == '1,5,6') {
        this.bookingListParam.startDate = ''
        this.bookingListParam.endDate = ''
      } else {
        this.initTimeParam()
      }
      this.bookingListParam[key] = val
    },
    // 关闭获取running list的websocket
    closeRunningBooking () {
      if (this.$createWebsocket.pageRequest && this.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'pageRunningEventPage') // 停止websocket
      }
    },
    changeTab (_$component) {
      this.activeName = _$component.name
      this.switchPage(_$component.name.split('.')[1])
      this.isShow.edit = {}
    },
    // 切换页面
    switchPage (pageView, init) {
      if (pageView == this.lastPageView) return
      this.$nextTick(() => {
        this.addOrRemoveOrangeColor(this.pendingEvent)
      })
      this.lastSelectList[this.lastPageView] = this.isSelectedList // 记录上次选中的事件
      this.lastPageView = pageView
      this.dataList = []
      this.initIsShowKey('edit')
      if (!init) { // 谷歌记录
        bookingAnalyze(`booking${pageView.slice(0, 1).toUpperCase()}${pageView.slice(1)}Open`, '/bk')
      }
      this.loading = true // 添加loading效果
      this.isSelectedList = this.lastSelectList[pageView] // 从上次记录中取选中的事件
      let copyFlag
      switch (pageView) {
        case 'viewSchedule':
          this.changeParAGetList('status', [0, 8])
          this.closeRunningBooking()
          copyFlag = true
          break
        case 'runningBookings':
          this.changeParAGetList('status', [1, 5, 6])
          this.sendRunningList() // 发送请求获取running list
          copyFlag = false
          break
        case 'pastBookings':
          this.changeParAGetList('status', [2])
          this.closeRunningBooking()
          copyFlag = false
          break
        case 'cancelledBookings':
          this.changeParAGetList('status', [4, 7])
          this.closeRunningBooking()
          copyFlag = false
          break
      }
      this.$emit('switchTab', copyFlag)
    },
    // 更改参数并获取booking列表
    changeParAGetList (key, val) {
      this.changeBookingListPar(key, val)
      this.getList()
    },
    // 初始化时间
    initDate (time) {
      const year = time.getFullYear()
      const month = this.getTime(time.getMonth() + 1)
      const day = this.getTime(time.getDate())
      return year + '-' + month + '-' + day + ' 00:00:00'
    },
    getTime (time) {
      return time < 10 ? '0' + time : time
    },
    // 获取列表
    getList (from) {
      if (from == 'apply') bookingAnalyze('bookingDateFilterClick', '/bk')
      this.axios.post(Outil.getUrl('/event/page'), this.bookingListParam, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
        .then(res => {
          if (res.status == 200 && res.data.errorCode == '0x0') {
            this.copyItem = ''
            this.$emit('searchBookingList')
            if (res.data.result) this.getListCallback(res.data.result)
            this.loading = false
          }
        }).catch(() => {})
    },
    // 获取列表后的操作
    getListCallback (data) {
      this.dataList = data.list // booking列表
      /*
      * this.dataList = data.resultList;
      * 给列表绑定是否选中值
      */
      const isSelectedList = this.isSelectedList
      this.isSelectedList = {}
      let allSel = Boolean(this.dataList.length)
      this.deleteActive = false
      for (var i = 0; i < this.dataList.length; i++) {
        // 解析对象中的json
        data.list[i].parameters = JSON.parse(data.list[i].parameters)
        data.list[i].destination = JSON.parse(data.list[i].destination)
        data.list[i].source = JSON.parse(data.list[i].source)
        let select = false
        for (const key in isSelectedList) {
          if (isSelectedList[key].id == this.dataList[i].eventId && isSelectedList[key].selected) {
            select = true
          }
        }
        if (!select) allSel = false
        if (select) this.deleteActive = true
        this.isSelectedList[this.dataList[i].eventId] = { index: i, selected: select, id: this.dataList[i].eventId }
      }
      this.allSel = allSel // 取消全选按钮
      // 分页
      this.total = data.pages * data.pageSize
      this.currentPage = data.pageNum
      this.pageSize = data.pageSize
    },
    // pageSize回调
    handleSizeChange (val) {
      this.pageSize = val
      this.currentPage = 1
      this.changeParAGetList('pageSize', val)
      this.updateSocketParams()
    },
    // 切换页码回调
    handleCurrentChange (val) {
      this.currentPage = val
      this.changeParAGetList('pageNum', val)
      this.updateSocketParams()
    },
    updateSocketParams (type, module) {
      const message = {
        currentPageNum: this.currentPage,
        everyPageNum: this.pageSize,
        module: 'pageRunningEventPage',
        type: 'pageRunningEventPage',
        data: ''
      }
      if (this.pageRequest.sendMsgs.pageRunningEventPage) {
        this.pageRequest.sendMsgs.pageRunningEventPage.message = message
      }
    },
    // 换算状态
    transfromEventStatus (status, failedCase, isTitle) {
      if (status == 5 && isTitle || status == 6 && isTitle) {
        return this.getLogContent(failedCase)
      }
      var failedCode = ''
      if (status == 4) failedCode = failedCase
      var eStatus = ''
      if (status == 4) {
        switch (failedCode) {
          case 300:
          case 301:
          case 302:
          case 303:
          case 304:
            eStatus = 'lang.interrupted'
            break
          case 100:
          case 101:
          case 102:
          case 103:
          case 104:
          case 200:
          case 202:
          case 297:
          case 298:
          case 299:
            eStatus = 'lang.failed'
            break
        }
      } else if (status == 6) {
        switch (failedCase) {
          case 499:
            eStatus = 'lang.pending'
            break
        }
      } else {
        switch (status) {
          case 0:
            eStatus = 'lang.planning'
            break
          case 1:
            eStatus = 'lang.running'
            break
          case 2:
            eStatus = 'lang.completedStatus'
            break
          case 3:
            eStatus = 'lang.deleted'
            break
          case 4:
            eStatus = 'lang.interrupted'
            break
          case 5:
            eStatus = 'lang.pending'
            break
        }
      }
      return eStatus
    },
    getLogContent (failedCase) {
      var logText = ''
      switch (failedCase) {
        case 100:
        case 101:
          logText = 'lang.notPaired'
          break
        case 102:
        case 103:
          logText = 'lang.DeviceOffLine'
          break
        case 104:
          logText = 'lang.sourceOccupied'
          break
        case 200:
          logText = 'lang.receiverOccupied'
          break
        case 202:
          logText = 'lang.liveFailed'
          break
        case 297:
          logText = 'lang.serviceClosed'
          break
        case 298:
        case 299:
          logText = 'lang.receiverOffline'
          break
        case 300:
          logText = 'lang.receiverStopped'
          break
        case 301:
        case 303:
          logText = 'lang.sourceSwitched'
          break
        case 302:
        case 304:
          logText = 'lang.receiverStoppedManually'
          break
        case 499:
          logText = 'lang.waitCalled'
          break
      }
      return logText
    },
    // log内容
    getLogText (failedCase) {
      var logText
      switch (failedCase) {
        case 100:
        case 101:
          logText = 'lang.notPaired'
          break
        case 102:
        case 103:
          logText = 'lang.DeviceOffLine'
          break
        case 104:
          logText = 'lang.sourceOccupied'
          break
        case 200:
          logText = 'lang.receiverOccupied'
          break
        case 202:
          logText = 'lang.liveFailed'
          break
        case 297:
          logText = 'lang.serviceClosed'
          break
        case 298:
        case 299:
          logText = 'lang.receiverOffline'
          break
        case 300:
          logText = 'lang.receiverStopped'
          break
        case 301:
        case 303:
          logText = 'lang.sourceSwitched'
          break
        case 302:
        case 304:
          logText = 'lang.receiverStoppedManually'
          break
      }
      return logText
    },
    // 编辑预约事件
    editEvent (event, index) {
      bookingAnalyze('bookingTableEventEditOpen', '/bk')
      this.lastDataList = JSON.parse(JSON.stringify(this.dataList))
      this.changeIsShow('edit', true, event.id) // 更改编辑状态
      this.changeIsShow('tType', event.ttype, index) // 更改t的类型
      this.initIsShowKey('sourceList') // 隐藏source列表
      this.initIsShowKey('RList') // 隐藏R列表
    },
    // 取消编辑
    editCancel (index) {
      bookingAnalyze('bookingTableEventEditClose', '/bk')
      this.dataList = this.lastDataList
      this.changeIsShow('edit', false, index)
    },
    // 更改事件选中状态
    isSelected (index, item) {
      if (!item) return
      this.copyItem = index // 复制事件
      this.$emit('copyIndex', this.dataList[index]) // 向父级传递copy选中的预约事件
      var flag = true // 全部选中
      var flagDelete = false // 禁止删除按钮
      this.isSelectedList[item.id].selected = !this.isSelectedList[item.id].selected
      for (const key in this.isSelectedList) {
        if (!this.isSelectedList[key].selected) {
          flag = false // 只有有一个没选中就取消全选按钮
        }
        if (this.isSelectedList[key].selected) {
          flagDelete = true // 只要有一个事件被选中，激活删除按钮
        }
      }
      this.isSelectedList = JSON.parse(JSON.stringify(this.isSelectedList))
      this.allSel = flag
      this.deleteActive = flagDelete
    },
    // 全选按钮
    isAllSel () {
      if (this.allSel) {
        this.allSel = false
        for (const key in this.isSelectedList) {
          this.isSelectedList[key].selected = false
        }
        this.deleteActive = false
      } else {
        this.allSel = true
        for (const key in this.isSelectedList) {
          this.isSelectedList[key].selected = true
        }
        this.deleteActive = true
      }
    },
    // 获取列表中的时间
    getListTime (time) {
      time = new Date(time)
      const hour = this.getTime(time.getHours())
      const min = this.getTime(time.getMinutes())
      const s = this.getTime(time.getSeconds())
      return `${hour}:${min}:${s}`
    },
    // 获取列表中的日期
    getListDate (time) {
      time = new Date(time)
      var year = time.getFullYear()
      var month = this.getTime(time.getMonth() + 1)
      var day = this.getTime(time.getDate())
      return year + '-' + month + '-' + day
    },
    // 更改显示值
    changeIsShow (key, val, index) {
      if (key == 'edit') {
        for (const itemKey in this.isShow[key]) {
          this.isShow[key][itemKey] = false
        }
      }
      this.isShow[key][index] = val
      var editArr = this.isShow.edit
      var RListArr = this.isShow.RList
      var sourceListArr = this.isShow.sourceList
      var tTypeArr = this.isShow.tType
      this.isShow = { edit: editArr, RList: RListArr, sourceList: sourceListArr, tType: tTypeArr }
    },
    // 隐藏r或source列表
    initIsShowKey (key) {
      const newArr = this.isShow[key]
      for (var i = 0; i < newArr.length; i++) {
        newArr[i] = false
      }
      this.isShow[key] = newArr
    },
    // 获取R列表
    getRList (condition, index) {
      this.rParam.condition = condition
      if (condition) {
        this.rParam.pageNum = 1
        this.totalPage.r = 1
      }
      if (this.totalPage.r < this.rParam.pageNum) return
      this.rParam.tpeerId = this.dataList[index].tpeerid
      this.axios({
        method: 'post',
        url: Outil.getUrl('/event/listSourceR'),
        data: this.rParam,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          this.totalPage.r = res.data.result.pages || 1
          const data = res.data.result.list
          if (this.rParam.pageNum == 1) {
            this.RList = data
            return
          }
          data.map((v, i) => {
            this.RList.push(v)
          })
        }
      }).catch(() => {})
    },
    // 获取source列表
    getSourceList (condition) {
      $('.row_section').click()
      this.sourceParam.condition = condition
      if (condition) {
        this.sourceParam.pageNum = 1
        this.totalPage.source = 1
      }
      if (this.totalPage.source < this.sourceParam.pageNum) {
        return
      }
      pageUserSource(this.sourceParam).then(res => {
        const result = res.data.result
        this.totalPage.source = result.pages || 1
        this.sourceList = this.sourceList.concat(result.list)
      }).catch(() => {})
    },
    // 更改设备
    updateDevice (obj, listIndex, deviceType) {
      if (deviceType == 'source') deviceType = 't'
      this.dataList[listIndex][`${deviceType}name`] = obj.name
      this.dataList[listIndex][`${deviceType}peerid`] = obj.peerId
      const start = this.dataList[listIndex].startTime
      const end = this.dataList[listIndex].endTime
      this.dataList[listIndex].startTime = typeof start == 'object' ? start.getTime() : start
      this.dataList[listIndex].endTime = typeof end == 'object' ? end.getTime() : end
      if (deviceType == 't') {
        const source = {
          peerFullId: obj.peerId,
          peerName: obj.name,
          type: obj.type,
          url: obj.url
        }
        this.dataList[listIndex].source = source
        this.dataList[listIndex].ttype = obj.type
      } else {
        this.dataList[listIndex].destination.fullId = obj.peerId
      }
      // 改变view层
      this.dataList = JSON.parse(JSON.stringify(this.dataList))
    },
    // 删除事件
    deleteEvent () {
      bookingAnalyze('bookingTableEventDelete', '/bk')
      var eventIds = []
      for (const key in this.isSelectedList) {
        if (this.isSelectedList[key].selected) {
          eventIds.push(this.isSelectedList[key].id)
        }
      }
      if (eventIds.length == 0) return
      this.$confirm(this.$t('lang.deleteBookingPrompt'), '', {
        confirmButtonText: this.$t('lang.btn'),
        cancelButtonText: this.$t('lang.cancel'),
        type: ''
      }).then(() => {
        this.axios({
          method: 'post',
          url: Outil.getUrl('/event/delete'),
          data: { ids: eventIds, booking: true },
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }).then(res => {
          if (res.data.errorCode == '0x0') {
            this.$message({
              message: this.$t(toTips[res.data.errorCode]),
              type: 'success'
            })
            // 删除成功后刷新列表
            this.getList()
          } else {
            const errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
            this.$message.error(errorInfo)
          }
        }).catch(() => {})
      }).catch(() => {})
    },
    updateEvent (index) {
      const item = this.dataList[index]
      bookingAnalyze('bookingTableEventEditSave', '/bk')
      item.startTime = typeof item.startTime == 'string' ? new Date(item.startTime) : item.startTime
      item.endTime = typeof item.endTime == 'string' ? new Date(item.endTime) : item.endTime
      if (!item.startTime) {
        this.$message.error(this.$t('lang.enterStartTime'))
        return
      }
      if (!item.endTime) {
        this.$message.error(this.$t('lang.enterEndTime'))
        return
      }
      const params = {
        rpeerid: item.rpeerid,
        tpeerid: item.tpeerid,
        delay: item.parameters.delay,
        bitrate: item.parameters.bitrate,
        startTime: Number(typeof item.startTime == 'object' ? item.startTime.getTime() : item.startTime),
        endTime: Number(typeof item.endTime == 'object' ? item.endTime.getTime() : item.endTime),
        ttype: item.ttype,
        title: item.title.trim(),
        description: item.description,
        reporter: item.parameters.reporter,
        tname: item.tname,
        color: item.color,
        turl: item.source.url,
        eventId: item.eventId,
        booking: true,
        userGroupId: item.userGroupId
      }
      params.delay = this.inputScope(params.delay, 0.5, 50)
      params.bitrate = this.inputScope(params.bitrate, 100, 10240)
      if (!params.title.trim() && params.title !== 0) {
        this.$message.error(this.$t('lang.titleEmpty'))
        return
      }
      if (params.startTime <= new Date().getTime() && this.activeName != 'lang.runningBookings') { // 开始时间必须大于当前时间
        this.$message.error(this.$t('lang.startTimeMoreCurTime'))
        return
      }
      if (params.endTime <= new Date().getTime()) { // 结束时间必须大于当前时间
        this.$message.error(this.$t('lang.endTimeMoreCurTime'))
        return
      }
      if (params.endTime <= params.startTime) { // 结束时间必须大于开始时间
        this.$message.error(this.$t('lang.endTimeMoreStartTime'))
        return
      }
      this.axios({
        method: 'post',
        url: Outil.getUrl('/event/update'),
        data: params,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        if (res.status == 200) {
          if (res.data.errorCode == '0x0') {
            this.changeIsShow('edit', false, index)
            this.getList()
          } else {
            const errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
            this.$message.error(errorInfo)
          }
        }
      }).catch(() => {})
    },
    goodNight (eventId) {
      bookingAnalyze('bookingTableEventLiveEnd', '/bk')
      this.$confirm(this.$t('lang.sureDeleteEvent'), '', {
        confirmButtonText: this.$t('lang.Ok'),
        cancelButtonText: this.$t('lang.cancel'),
        type: ''
      }).then(() => {
        this.axios({
          method: 'post',
          url: Outil.getUrl('/event/endEventById'),
          data: { eventId: eventId, booking: true },
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }).then(res => {
          if (res.status == 200 && res.data.errorCode == '0x0') {
            this.$message({
              message: this.$t(toTips[res.data.errorCode]),
              type: 'success'
            })
            // 删除成功后刷新列表
            this.getList()
          } else {
            const errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
            this.$message.error(errorInfo)
          }
        }).catch(() => {})
      }).catch(() => {})
    },
    updateDelayBit (index, key, min, max) {
      this.dataList[index].parameters[key] = this.dataList[index].parameters[key].toString().replace(/[^\d.]/g, '')
      if (this.dataList[index].parameters[key] < min) {
        this.dataList[index].parameters[key] = min
      }

      if (this.dataList[index].parameters[key] > max) {
        this.dataList[index].parameters[key] = max
      }
    },
    // 延迟和比特率输入的范围
    inputScope (val, min, max) {
      if (val) {
        val = val.toString().replace(/[^\d.]/g, '')
        val = val < min ? min : val
        val = val > max ? max : val
        val = val.toString()
      }
      if (val && val.includes('.')) {
        const valArr = val.split('.')
        val = `${valArr[0]}.${valArr[1]}`
        val = Number(valArr[1]) ? val : val.split('.')[0]
      }
      return val
    }
  },
  filters: {
    calcDuration (start, end, list) {
      start = typeof start == 'object' ? start.getTime() : start
      end = typeof end == 'object' ? end.getTime() : end
      if (end < start) {
        return '00:00:00'
      }
      const h = Outil.timeFormat(Math.floor((end - start) / 1000 / 60 / 60))
      const min = Outil.timeFormat(Math.floor((end - start) % (1000 * 60 * 60) / 1000 / 60))
      const s = Outil.timeFormat(Math.floor((end - start) % (1000 * 60) / 1000))
      return `${h}:${min}:${s}`
    },
    // 去掉多余的小数
    removeExcessDecimals (value) {
      if (value == null || value == undefined) {
        value = ''
      }
      const valArr = value.toString().split('.')
      if (valArr.length > 1) {
        return `${valArr[0]}.${valArr[1].slice(0, 2)}`
      }
      return value
    },
    filterEventType (value) {
      let msg = ''
      if (value == 0) {
        msg = 'PGC'
      } else if (value == 1) {
        msg = 'UGC'
      }
      return msg
    }
  }
}
</script>
<style lang='less' scoped>
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  background-color: rgb(233, 233, 233);
  opacity: 0.7;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 5px;
  background: none;
}
.table_view {
  ::v-deep .el-tabs__header {
    margin: 0;
  }
  height: 100%;
  > .content {
    height: calc(100% - 100px);
  }
  .content_list_item .more {
    display: none;
  }
  .content_list_item.edit_status {
    ::v-deep .pull_down_box .el-select {
      .el-input__suffix {
        display: block !important;
      }
      .el-input__inner {
        background: #444;
        padding-right: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .more {
      display: block;
      position: absolute;
      right: 18px;
      top: 60%;
      // transform: translateY(-50%);
      z-index: 1000;
    }
  }
  .start_time {
    ::v-deep .el-input .el-input__inner {
      background: #444;
      color: #fff;
    }
  }
  ::v-deep .filter .el-input__inner {
    background: #444;
    border: none;
    padding: 0;
    text-align: center;
    color: #fff;
    height: 100%;
    font-size: 13px;
  }
  ::v-deep
    .el-tabs
    .el-tabs__header
    .el-tabs__nav
    .el-tabs__item.is-top.orangeColor {
    color: orange !important;
    ::v-deep input {
      color: orange;
    }
  }
  ::v-deep
    .el-tabs
    .el-tabs__header
    .el-tabs__nav
    .el-tabs__item.is-top.is-active.orangeColor {
    color: #33ab4f;
  }
  // ::v-deep .el-tabs .el-tabs__header .el-tabs__nav .el-tabs__item.is-top.is-active.whiteColor {
  //     color: #fff;
  // }
  .booking-tabel-section.width200 .el-col-2 {
    max-width: 129px;
  }
  .booking-tabel-section.width200 .el-col-4 {
    // flex: 0 0 180px;
    max-width: 200px;
  }
  .booking-tabel-section.width250 .el-col-2 {
    max-width: 160px;
  }
  .booking-tabel-section.width250 .el-col-4 {
    // flex: 0 0 180px;
    max-width: 250px;
  }
  .orangeColor {
    color: orange !important;
    ::v-deep input {
      color: orange;
    }
  }
  .logContent {
    color: #ccc;
    z-index: 10;
    padding: 0px 10px;
    position: absolute;
    top: 45px;
    left: 20px;
    border-radius: 5px;
    background: #666;
    white-space: nowrap;
  }
  ul {
    margin-bottom: 0;
  }
  .page_menu {
    padding-left: 15px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #000;
    background-color: #333;
    font-size: 16px;
    box-sizing: border-box;
    li {
      height: 50px;
      width: 180px;
      text-align: center;
    }
    li.active {
      border-bottom: 2px solid #33ab4f;
    }
  }
  .filter {
    height: 50px;
    background: #555;
    border-bottom: 1px solid #000;
    padding-left: 35px;
    text-align: left;
    .el-input__icon {
      display: none;
    }
    .el-date-editor.el-input {
      width: 100%;
      height: 100%;
      background: #444;
    }
    .el-input__inner {
      background: #444;
      border: none;
      padding: 0;
      text-align: center;
      color: #000;
      height: 100%;
      font-size: 13px;
    }
    .title {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
    }
    .time {
      width: 135px;
      height: 30px;
      line-height: 30px;
      background-color: #fff;
      margin-top: 10px;
      border-radius: 2px;
      color: #000;
      text-align: center;
      margin-left: 5px;
      overflow: hidden;
      ::v-deep .el-input__prefix,
      ::v-deep .el-input__suffix {
        display: none;
      }
    }
    .interval {
      background: #fff;
      width: 16px;
      height: 3px;
      margin: 22px 10px;
      border-radius: 1px;
    }
    .apply {
      width: 80px;
      height: 30px;
      line-height: 26px;
      border-radius: 4px;
      text-align: center;
      margin-left: 30px;
      margin-top: 10px;
      font-size: 14px;
      cursor: pointer;
      border: 1px solid #39aa50;
      background-color: #39aa50;
    }
    .apply:hover {
      background-color: #209d3d;
    }
    .page {
      height: 100%;
      margin-right: 70px;
      .paginationBox {
        margin: 0;
        margin-top: 7px;
      }
    }
    .delete {
      // margin-right: 100px;
      margin-right: 10px;
      margin-top: 13px;
      span {
        background-image: url(../../assets/img/alert_delete_none.png);
        background-repeat: no-repeat;
        background-position: 0 -2px;
        display: block;
        padding-left: 30px;
        font-size: 16px;
        cursor: not-allowed;
        height: 24px;
        line-height: 24px;
        margin-left: 20px;
      }
      span.active {
        background-image: url(../../assets/img/alert_delete_records.png);
        cursor: pointer;
      }
    }
  }
  .content {
    font-size: 14px;
    .content_row {
      height: 50px;
      line-height: 50px;
      .bit,
      .start_time,
      .end_time {
        line-height: 20px;
        padding-top: 5px;
      }
      button {
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        border-radius: 4px;
        border: 1px solid #0f620a;
        background-color: #0c6;
        padding: 1px 6px;
      }
      .grid-content button:first-child {
        margin-right: 10px;
      }
      .cancel {
        border: 1px solid #524c4c;
        background-color: #a0a0a0;
        color: #fff;
        margin-right: 12px;
      }
      .select_box {
        background-image: url(../../assets/img/v3_selectedIcon.png);
        background-repeat: no-repeat;
        background-position: 6px -42px;
        height: 24px;
        padding-left: 36px;
        font-size: 0.16rem;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 20%;
      }
      .select_box.active {
        background-position: 6px -15px;
      }
    }
    .content_title {
      padding-left: 2%;
      border-bottom: 2px solid #217536;
      background-color: #3a3a3a;
      color: #8c8a88;
    }
    .content_detail {
      ::v-deep input {
        font-size: 12px;
      }
      .grid-content {
        margin: 0 auto;
        width: 96%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // padding-right: 15px;
        ::v-deep .el-input__prefix,
        ::v-deep .el-input__suffix {
          display: none;
        }
      }
      .start_time,
      .end_time {
        ::v-deep input {
          height: 30px;
          color: #fff;
          padding: 0 10px;
          background: #444;
          border: none;
        }
      }
    }
    .content_detail.orangeColor .device_pull_box {
      ::v-deep .el-input.is-disabled {
        .el-input__inner {
          color: orange;
        }
      }
    }
    .content_list_item {
      border-bottom: #333 solid 1px;
      padding-left: 2%;
      ::v-deep .start_time .el-input__prefix,
      ::v-deep .end_time .el-input__prefix {
        display: none;
      }
    }
    .content_list_item:hover {
      background-color: rgba(57, 170, 80, 0.25);
    }
    .content_list_item.active {
      background-color: rgba(57, 170, 80, 0.25);
    }
    .more {
      position: absolute;
      top: 60%;
      transform: translateY(-50%);
      right: 0;
      border-top-width: 10px;
      border-bottom-width: 10px;
    }
    .tab-tr-rlist {
      background: rgba(228, 228, 228, 1);
      border: 1px solid rgba(153, 153, 153, 1);
      position: absolute;
      top: 41px;
      left: 5px;
      right: 5px;
      z-index: 1;
      border-radius: 4px;
      padding: 5px;
      line-height: 1em;
      input {
        width: 100%;
        padding-left: 5px;
        padding-right: 30px;
        border-radius: 4px;
        margin-bottom: 5px;
        height: 30px;
        border: none;
      }
      .icon-search {
        color: #666;
        font-size: 18px;
        position: absolute;
        right: 10px;
        top: 13px;
        cursor: pointer;
      }
      .pull-list {
        height: 150px;
        overflow-y: scroll;
        overflow-x: hidden;
        li {
          height: 30px;
          line-height: 30px;
          width: 100%;
          background: rgba(228, 228, 228, 1);
          font-size: 12px;
          color: #666;
          padding: 0 5px;
          cursor: pointer;
          text-align: left;
        }
        li:hover {
          background: rgba(0, 208, 105, 0.2);
        }
      }
    }
  }
  .grid-content {
    min-height: 36px;
    input {
      color: #fff;
      background: #444;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      height: 35px;
    }
  }
  .grid-content.ipt {
    line-height: 50px !important;
    padding-top: 0 !important;
    ::v-deep input,
    input {
      width: 93%;
      height: 30px;
      border: none;
      border-radius: 5px;
      padding: 0;
      padding-left: 5px;
    }
  }
  .grid-content.ipt.bit {
    ::v-deep .el-input {
      display: inline;
      input {
        background: #444;
      }
    }
    ::v-deep input,
    input {
      width: 40%;
    }
    input:first-child {
      margin-right: 3px;
    }
  }
  [role='button'] {
    color: #fff;
  }
  .orangeColor [role='button'] {
    color: orange;
  }
  .el-input .el-input__inner {
    background: #fff;
  }
}
.el-popper[x-placement^='bottom'] .popper__arrow:after {
  border-bottom-color: #fff;
}
.table_view .filter .el-input__inner {
  cursor: pointer;
}
.el-input.el-input--small .el-input__inner {
  background: #fff;
  border-color: #e4e4e4;
}
@media (min-width: 760px) and (max-width: 1279px) {
  .table_view {
    .filter {
      padding-left: 6px;
      .time_filter {
        .title {
          font-size: 12px;
        }
        .time {
          width: 120px;
          ::v-deep .el-input .el-input__inner {
            font-size: 11px;
          }
        }
        .interval {
          width: 8px;
          margin: 22px 5px;
        }
        .apply {
          margin-left: 5px;
          width: 65px;
        }
      }
      .page {
        margin-right: 5px;
        .paginationBox {
          margin-top: 12px;
        }
      }
      .delete {
        margin-right: 10px;

        span {
          font-size: 13px;
          margin-left: 0px;
        }
      }
    }
  }
  .booking-tabel-section {
    overflow: auto;
    height: auto;
  }

  .booking-tabel-section .el-col-1 {
    flex: 0 0 70px;
  }
  .booking-tabel-section .el-col-2 {
    flex: 0 0 101px;
  }
  .booking-tabel-section .el-col-4 {
    flex: 0 0 180px;
  }
  .booking-tabel-section .content_row {
    width: 1280px;
  }

  .booking-tabel-section .el-col-4 .log-grid-header-row {
    white-space: normal !important;
    word-break: break-all !important;
    line-height: normal !important;
    padding: 15px 0px;
  }
}

@media (min-width: 900px) and (max-width: 1279px) {
  .table_view {
    .filter {
      .time_filter {
        .time {
          width: 140px;
          ::v-deep .el-input .el-input__inner {
            font-size: 13px;
          }
        }
        .title {
          font-size: 14px;
        }
        .delete {
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (min-width: 500px) and (max-width: 759px) {
  .booking-tabel-section {
    width: 100%;
  }
  .row_section {
    width: 1280px;
  }
  .table_view {
    .filter {
      padding-left: 10px;
      height: auto;
      float: left;
      width: 100%;
      padding-bottom: 10px;
      .time_filter {
        width: 100%;
        display: flex;
        padding: 0 10px 0 0;

        .time {
          width: 130px;
          ::v-deep .el-input .el-input__inner {
            font-size: 12px;
          }
        }
        .interval {
          width: 15px;
          margin: 22px 5px;
        }
        .apply {
          margin-left: 5px;
        }
      }
      .page {
        margin-right: 10px;
        .paginationBox {
          margin-top: 12px;
        }
      }
      .delete {
        margin-right: 10px;
        float: left;

        span {
          margin-left: -3px;
        }
      }
    }
  }

  .booking-tabel-section {
    overflow: auto;
    height: auto;
  }

  .booking-tabel-section .el-col-1 {
    flex: 0 0 70px;
  }
  .booking-tabel-section .el-col-2 {
    flex: 0 0 101px;
  }
  .booking-tabel-section .el-col-4 {
    flex: 0 0 180px;
  }
  .booking-tabel-section .content_row {
    width: 1280px;
  }

  .booking-tabel-section .el-col-4 .log-grid-header-row {
    white-space: normal !important;
    word-break: break-all !important;
    line-height: normal !important;
    padding: 15px 0px;
  }
}

@media (min-width: 1280px) and (max-width: 1599px) {
  .booking-tabel-section .el-col-1 {
    width: 6.16667%;
  }

  .booking-tabel-section .el-col-4 .log-grid-header-row {
    white-space: normal !important;
    word-break: break-all !important;
    line-height: normal !important;
    padding: 16px 0px;
  }
}
@media (min-width: 760px) and (max-width: 1279px) {
  .row_section {
    width: 1280px;
  }
  .responsive_pagination {
    .paginationBox {
      .inputPageModule {
        margin-right: 4px;
      }
    }
  }
  ::v-deep .el-tabs__header {
    position: relative;
    .el-tabs__nav-wrap {
      position: absolute;
      top: 0;
    }
  }
}
@media (width: 1280px) {
  ::v-deep .el-tabs__header {
    position: relative;
    .el-tabs__nav-wrap {
      position: absolute;
      top: 0;
    }
  }
}
.page_menu {
  ::v-deep .el-tabs {
    height: 100%;
    max-width: 760px;
    .el-tabs__header {
      height: 100%;
      position: relative;
      .el-tabs__nav-wrap,
      .el-tabs__nav-scroll,
      .el-tabs__nav.is-top.is-stretch {
        height: 100%;
      }
      .el-tabs__nav {
        .el-tabs__item.is-top {
          // margin-left: -50px;
          // margin-left: 150px;
        }
      }
    }
  }
}
@media (min-width: 1280px) and (max-width: 3000px) {
  .paginationBox {
    padding-top: 5px;
  }
  .el-tabs__header {
    margin: 0;
  }
  // .page_menu{
  //     .el-tabs {
  //         .el-tabs__header{
  //             .el-tabs__nav{
  //                 .el-tabs__item.is-top{
  //                     margin-left: -50px;
  //                 }
  //             }
  //         }
  //     }

  // }
}
.row_section {
  height: 520px;
  overflow-y: auto;
  overflow-x: hidden;
}
.el-tabs .el-tabs__header .el-tabs__nav .el-tabs__item.is-top {
  color: #fff;
  line-height: 47px;
  // width: 170px;
}
</style>
