import request from '@/assets/js/axiosExtend'
export function getChannelPage (params) {
  return request({
    url: '/ccp/tvucc-user/channel/page',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function listUserGroup (params) {
  return request({
    url: '/ccp/tvucc-user/userGroup/listUserGroup',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function addGroup (params) {
  return request({
    url: '/ccp/tvucc-user/userGroup/addGroup',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function editGroup (params) {
  return request({
    url: '/ccp/tvucc-user/userGroup/updateGroup',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function deleteGroup (params) {
  return request({
    url: '/ccp/tvucc-user/userGroup/deleteGroup',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function setUserDeviceGroup (params) {
  return request({
    url: '/ccp/tvucc-user/userDeviceGroup/setUserDeviceGroup',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function pageUserSource (params) {
  return request({
    url: '/ccp/tvucc-user/userDevice/pageUserSource',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      isCancelToken: true
    }
  })
}
// 查询Source下的R
export function getRList (params) {
  return request({
    url: '/ccp/tvucc-user/channel/listR',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function pageBookingChannel (params) {
  return request({
    url: '/ccp/tvucc-user/userDevice/pageBookingChannel',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 设置设备为手动/自动
export function setManual (params) {
  return request({
    url: '/ccp/tvucc-device/deviceExtra/setManual',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 手动通道开启直播
export function manualStartLive (params) {
  return request({
    url: '/ccp/tvucc-device/live/manualStartLive',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 关闭直播
export function stopLive (params) {
  return request({
    url: '/ccp/tvucc-device/live/stopLive',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 自动通道开启直播
export function autoStartLive (params) {
  return request({
    url: '/ccp/tvucc-device/live/autoStartLive',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 获取推流账号
export function listAccountInfo (params, mediaApiUrl) {
  return request({
    url: `${mediaApiUrl}/tvucc-media/account/listAccountInfo?type=${params.type}&nickName=${params.nickName}&detail=${params.detail}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
    method: 'get'
  })
}

// check推流账号是否已过期
export function checkSocialAccount (params, mediaApiUrl) {
  return request({
    url: `${mediaApiUrl}/tvucc-media/media/checkAuth`,
    method: 'get',
    params,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
// 开始推流
export function startShare (params) {
  return request({
    url: '/ccp/tvucc-device/share/start',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 停止推流
export function stopShare (params) {
  return request({
    url: '/ccp/tvucc-device/share/stop',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 查询R是手动还是自动
export function checkRIsManual (parmas) {
  return request({
    url: '/ccp/tvucc-device/deviceExtra/isManual',
    method: 'post',
    data: parmas,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// booking中获取推流地址
export function listBookingShareIds (params) {
  return request({
    url: '/ccp/tvucc-booking/bookingShare/listBookingShareAccounts',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// sourceControl中查询推流地址
export function listShareAccount (params) {
  return request({
    url: '/ccp/tvucc-device/share/listShareAccount',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
