<template>
  <div class="preview">
    <div class="pre_header clearfix">
      <div class="f_left title">{{$t('lang.runningBooking')}}</div>
      <i
        class='el-icon-circle-plus-outline f_right icon_close pointer'
        @click='closePreview'></i>
    </div>
    <div class="pre_content">
      <div class="top_info clearfix">
        <div class="top_info_left f_left">
          <div class="t_name ellipsis f_left">{{preview.runningBook.tname}}</div>
          <div class="f_right">
            <div class="r_list">
              <span class="r_name f_left pr">{{preview.runningBook.rname}}
                <i class="more"></i>
              </span>
              <button>{{$t(preview.btnTitle)}}</button>
            </div>
          </div>
        </div>
        <div class="f_right setting_box">
          <span class="setting_icon f_left"></span>
          <span class='setting_text'>{{$t('lang.Setting')}}</span>
        </div>
      </div>
      <div class="video_box">
        <div class="audio f_left clearfix">
          <div class="clearfix">
            <div class="left_audio f_left">
              <Audio :audioOps='audioOpsL'></Audio>
              <!-- <div class="audio_bar"></div> -->
              <p>{{$t('lang.left')}}</p>
            </div>
            <div class="right_audio f_left">
              <Audio :audioOps='audioOpsR'></Audio>
              <!-- <div class="audio_bar"></div> -->
              <p>{{$t('lang.right')}}</p>
            </div>
          </div>
          <p style='font-size:16px;color:#999;text-align:left;'>{{$t('lang.audio')}}</p>
        </div>
        <div class="video f_left">
          <div
            id="videoService"
            class='video_service'>
            <video-module
              :height='"300px"'
              :width='"511px"'
              :source="preview.sourceItem"
              :deviceType='"T"'
              :rid="preview.runningBook.rpeerid"
              :showCamera='false'
              :cameraType='"126"'
              :useOldR='true'></video-module>
          </div>
        </div>
        <div class="delay_bit f_right">
          <div class="item_box pr">
            <div
              class='clearfix'
              style='line-height:30px'>
              <span class='f_left'>{{$t('lang.capitalDelay')}}(s)</span>
              <span class='dynamic f_right pr'>
                <span>{{$t(preview.canSetDelay)}}</span>
                <i class="more"></i>
              </span>
            </div>
            <div class="slider">
              <span class='min_delay f_left'>0.5</span>
              <el-slider
                v-model="preview.currentTDelay"
                show-input
                :max='50'
                :min='0.5'
                :show-tooltip="false"
                :disabled=true>
              </el-slider>
              <span class='max_delay f_right'>50</span>
            </div>
          </div>
          <div class="item_box bit pr">
            <div
              class='clearfix'
              style='line-height:30px'>
              <span class='f_left'>{{$t('lang.capitalBit')}}(kbps)</span>
              <span class='vbr f_right pr'>
                <span>{{$t(preview.currentTVideoMode)}}</span>
                <i class="more"></i>
              </span>
            </div>
            <div class="slider">
              <span class='min_delay f_left'>100</span>
              <el-slider
                v-model="preview.currentTBitrate"
                show-input
                :max='10240'
                :min='100'
                :disabled=true
                :show-tooltip="false">
              </el-slider>
              <span class='max_delay f_right'>10240</span>
            </div>
          </div>
        </div>
      </div>
      <div class="slot_info clearfix">
        <div class="chart f_left">
          <span
            class='f_left'
            style='margin-right:12px;'>{{$t('lang.quality')}}</span>
          <span
            class='f_left'
            style='font-size:14px;color:#33AB4F;'>{{preview.quality}}Kbps</span>
          <span class='f_right speed'>
            1.2Mb/s
            <i class="more f_right"></i>
          </span>
          <div class="qulityWidth">
            <div
              id="quality"
              :style="{width: '430px'}"></div>
          </div>
        </div>
        <div class="info_text f_left">
          <ul class='clearfix'>
            <li class="f_left">
              <p class='val'>{{preview.errorRate8s}}%</p>
              <p class='til'>{{$t('lang.errRate')}}(8s)</p>
            </li>
            <li class="f_left">
              <p class='val'>{{preview.line8s}}%</p>
              <p class='til'>{{$t('lang.lineQuality')}}(8s)</p>
            </li>
            <li class="f_left">
              <p class='val'>1:{{preview.bat1val}}&nbsp;&nbsp;2:{{preview.bat2val}}</p>
              <p class='til'>{{$t('lang.tBattery')}}</p>
            </li>
            <li class="f_left">
              <p class='val'>{{preview.tFormat}}</p>
              <p class='til'>{{$t('lang.tFormat')}}</p>
            </li>
            <li class="f_left">
              <p class='val'>{{preview.errorRate1s}}%</p>
              <p class='til'>{{$t('lang.errRate')}}(1s)</p>
            </li>
            <li class="f_left">
              <p class='val'>{{preview.quality1s}}%</p>
              <p class='til'>{{$t('lang.lineQuality')}}(1s)</p>
            </li>
            <li class="f_left">
              <p class='val'>{{preview.tInput}}</p>
              <p class='til'>{{$t('lang.tInput')}}</p>
            </li>
            <li class="f_left">
              <p class='val'>{{preview.rFormat}}</p>
              <p
                class='til'
                style='overflow:hidden'>{{$t('lang.rFormat')}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Audio from '@/components/Audio'
import videoModule from '@/components/VideoC'
import Highcharts from 'highcharts'
import { mapState } from 'vuex'
import { bookingAnalyze } from '@/assets/js/googleAnalyze/booking.js'

export default {
  props: {
    liveInfo: {
      type: Object
    },
    audio: {
      type: Object
    },
    preview: {

    }
  },
  components: {
    Audio,
    videoModule
  },
  data () {
    return {
      delayVal: 0,
      bitVal: 0,
      socketURL: '',
      rInfo: {
        categoryId: '2152857600',
        operationType: '101'
      },
      keyWord: 'getReceiverInfo',
      audioOpsL: {
        width: 21,
        height: 240,
        activeHeight: this.audio.left
      },
      audioOpsR: {
        width: 21,
        height: 240,
        activeHeight: this.audio.right
      },
      qualityTotalPoints: 300,
      options: {
        chart: {
          type: 'area',
          marginLeft: 38,
          marginRight: 1,
          marginBottom: 28,
          backgroundColor: '',
          animation: false
        },
        colors: ['Lime'],
        title: {
          text: ''
        },
        credits: {
          enabled: false // 版权信息
        },
        legend: {
          enabled: false // 图例
        },
        xAxis: {
          endOnTick: true,
          startOnTick: true,
          allowDecimals: false,
          gridLineWidth: 1, // x网格线宽度
          tickWidth: 2,
          tickLength: 1,
          lineColor: '#555',
          gridLineColor: '#555',
          tickColor: '#555',
          max: 10, // x最大值
          min: 0,
          // tickAmount:5,
          tickPositions: [0, 60, 120, 180, 240, 300],
          labels: { // 轴坐标和单位
            formatter: function () {
              return `${this.value / 60}:00` // 格式化x坐标显示
            },
            style: {
              color: '#999'
            }
          }
        },
        yAxis: {
          title: {
            text: ''
          },
          gridLineWidth: 1, // x网格线宽度
          // tickAmount:5,
          gridLineColor: '#555',
          lineColor: '#555',
          tickWidth: 0,
          tickLength: 0,
          tickInterval: 200,
          tickColor: '#555',
          max: 1200,
          labels: {
            formatter: function () {
              return parseInt(this.value, 10) / 1000
            },
            style: {
              color: '#999'
            }
          },
          x: -5 // 设置偏移y轴坐标
        },
        tooltip: {
          formatter: function () {
            return this.y + 'kbps'
          }
        },
        plotOptions: {
          area: {
            lineWidth: 0,
            marker: { // 鼠标移入选中的点
              enabled: false,
              symbol: 'circle',
              radius: 0,
              states: {
                hover: {
                  radius: 2,
                  fillColor: 'red',
                  lineColor: '',
                  lineWidth: 1,
                  border: 1
                }
              }
            }
          }
        },
        series: [{
          name: '',
          clip: false,
          zoneAxis: 'x',
          zones: [],
          data: [],
          states: {
            hover: {
              enabled: false
            }
          }
        }]
      }
    }
  },
  watch: {
    audio: {
      handler (val) {
        this.audioOpsL.activeHeight = this.audio.left
        this.audioOpsR.activeHeight = this.audio.right
      },
      deep: true
    },
    getCurrentRInfo (val) {
      this.updateTotalGraph()
    }
  },
  computed: {
    ...mapState({
      State: state => state
    }),
    getCurrentRInfo () {
      return this.State.ReceiverInfoObj
    }
  },
  mounted () {
    this.createQuality()
    this.updateTotalGraph()
  },
  methods: {
    closePreview () {
      bookingAnalyze('bookingLiveEventPlayClose', '/bk')
      this.$emit('closePreview')
    },
    createQuality () {
      this.options.series[0].data = []
      for (var i = 0; i < this.qualityTotalPoints; i++) {
        this.options.series[0].data.push({ color: '', y: null, x: i })
      }
      if (this.timers) clearTimeout(this.timers)
      this.timers = setTimeout(() => {
        if ($('#quality')[0]) {
          this.chart = Highcharts.chart('quality', this.options) // 绘制图表
        }
      }, 1000)
    },
    updateTotalGraph () {
      const qualityVal = this.State.ReceiverInfoObj.QualityMeasureOnGUI
      const errorRate = this.State.ReceiverInfoObj.ErrorRateLastSec
      if (typeof qualityVal != 'number' || errorRate == undefined) return
      // 区分显示颜色
      let color
      if (errorRate <= 0) {
        // 绿色
        color = 'Lime'
      } else if (errorRate > 0 && errorRate <= 10) {
        // 橙色
        color = 'orange'
      } else if (errorRate > 10) {
        // 红色
        color = 'red'
      }
      // 更新数组
      const data = JSON.parse(JSON.stringify(this.options.series[0].data))
      data.splice(this.qualityTotalPoints - 1, 1)
      data.forEach(v => {
        v.x += 1
      })
      data.unshift({ color, y: qualityVal || null, x: 0 })
      // // 处理图表显示色值区域
      const zones = this.formatColor(data)
      // 使用updata会重绘图表，会有性能问题，但是为了更新图标颜色，需要使用update函数
      if (!this.chart || !this.chart.series.length) return
      this.chart.series[0].update({
        data,
        zones
      })
    },
    // 用来处理图表显示不同颜色区域
    formatColor (data) {
      let color = []
      let lastColor
      lastColor = lastColor || data[0].color
      data.map(v => {
        if (v.y) {
          if (!color.length) {
            if (v.x == 0) {
              color.push({ value: v.x, color: v.color })
              lastColor = v.color
            }
          } else {
            if (lastColor != v.color) {
              color.push({ value: v.x, color: v.color })
              lastColor = v.color
            } else {
              if (color.length) {
                color[color.length - 1].value = v.x
              }
            }
          }
        }
      })
      return color
    }
  }
}
</script>
<style lang='less' scoped>
.preview {
  width: 1075px;
  height: 703px;
  background: #212121;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  box-shadow: 0px 0px 15px #100400;
  padding-bottom: 10px;
  border-radius: 5px;
  z-index: 1000;
  .pre_header {
    height: 40px;
    line-height: 40px;
    background: #33ab4f;
    border-radius: 4px 4px 0 0;
    font-size: 22px;
    .title {
      margin-left: 4%;
    }
    .icon_close {
      margin-right: 18px;
      margin-top: 7px;
      transform: rotate(45deg);
      font-size: 26px;
    }
  }
  .pre_content {
    margin: 10px auto 0 auto;
    width: 96%;
    .top_info {
      width: 95%;
      margin: 0 auto;
      padding-top: 10px;
      .top_info_left {
        width: 600px;
      }
      .t_name {
        width: 45%;
        text-align: left;
        font-size: 22px;
      }
      .r_name {
        width: 180px;
        height: 30px;
        line-height: 30px;
        background: #424242;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        text-align: left;
        padding-left: 10px;
        .more {
          position: absolute;
          top: 60%;
          right: 10px;
          transform: translateY(-50%);
          border-top-color: #fff;
          border-top-width: 8px;
        }
      }
      button {
        border: 1px solid #616161;
        color: #aaa;
        cursor: pointer;
        text-align: center;
        height: 0.3rem;
        text-decoration: none;
        line-height: 0.3rem;
        min-width: 0.76rem;
        font-size: 0.12rem;
        border-radius: 0.04rem;
        padding: 0 0.05rem;
        background: #616161;
        margin-left: 3px;
      }
      .setting_box {
        margin-right: 42px;
        opacity: 0.5;
        cursor: not-allowed;
        .setting_icon {
          width: 26px;
          height: 26px;
          color: #aaa;
          background: url('../../assets/img/settingIcon.png') no-repeat;
          background-size: contain;
        }
        .setting_text {
          margin-top: 3px;
          margin-left: 5px;
          float: right;
        }
      }
    }
    .video_box {
      margin: 0 auto;
      margin-top: 20px;
      height: 300px;
      width: 95%;
      .audio {
        width: 80px;
        .left_audio {
          margin-right: 10px;
        }
        // .audio_bar {
        //     width: 21px;
        //     height: 240px;
        //     margin-right: 10px;
        //     background: url(../../assets/img/audioBg.png) repeat-y scroll center 0 transparent;
        // }
        p {
          margin-right: 10px;
          margin-top: 12px;
          font-size: 12px;
        }
      }
      .video_service {
        position: relative;
        width: 511px;
        height: 300px;
        float: left;
        background: transparent;
      }
      .delay_bit {
        margin-left: 26px;
        padding: 36px 0 4px 0;
        height: 287px;
        position: relative;
        font-size: 14px;
      }
      ::v-deep .el-input.el-input--small input {
        background: #424242;
        color: #fff;
        width: 56px;
        height: 26px;
        line-height: 26px;
        border-radius: 5px;
        padding: 0;
        border: none;
        margin-left: 109px;
      }
      ::v-deep .el-input-number__decrease,
      ::v-deep .el-input-number__increase {
        display: none;
      }
      ::v-deep .slider {
        position: relative;
        width: 260px;
        margin-right: 42px;
        margin-top: 6px;
        .el-slider__input {
          position: absolute;
          top: -39px;
          left: -30px;
        }
        .el-slider__runway.show-input {
          width: 260px;
          height: 37px;
          border-radius: 0;
          background: #424242;
          opacity: 0.5;
        }
        .el-slider__runway .el-slider__bar {
          background: url('../../assets/img/delayImg.png');
          height: 37px;
          border-radius: 0;
        }
        .el-slider__runway .el-slider__button-wrapper .el-slider__button {
          width: 5px;
          height: 48px;
          border-radius: 0;
          border: 1px solid #999;
          background: #6c6c6c;
          margin-top: 8px;
        }
        .el-slider__runway .el-slider__button-wrapper .el-slider__button:hover {
          transform: scale(1);
        }
        .min_delay {
          position: absolute;
          left: -36px;
          top: 50%;
          transform: translateY(-50%);
          color: #aaa;
        }
        .max_delay {
          position: absolute;
          left: 270px;
          top: 50%;
          transform: translateY(-50%);
          color: #aaa;
        }
      }
      .bit {
        margin-top: 40px;
        ::v-deep .el-slider__input {
          left: -8px;
        }
        ::v-deep .slider .el-slider__runway .el-slider__bar {
          background: #33ab4f;
          height: 37px;
          border-radius: 0;
        }
      }
      .vbr {
        width: 88px;
      }
      .dynamic {
        width: 105px;
      }
      .dynamic,
      .vbr {
        height: 28px;
        line-height: 28px;
        border-radius: 6px;
        background: #616161;
        margin-right: 42px;
        color: #aaa;
        text-align: left;
        padding-left: 10px;
        .more {
          position: absolute;
          top: 65%;
          right: 15px;
          transform: translateY(-50%);
          border-top-width: 12px;
          border-top-color: #fff;
        }
      }
    }
    .slot_info {
      margin: 0 auto;
      margin-top: 40px;
      width: 95%;
      .chart {
        width: 430px;
        // min-width: 400px;
        #quality {
          height: 200px;
        }
        ::v-deep .el-select .el-input__inner {
          color: #aaa;
        }
      }
      .speed {
        width: 120px;
        height: 27px;
        line-height: 27px;
        background: #424242;
        opacity: 0.5;
        border-radius: 6px;
        .more {
          margin-top: 10px;
          margin-right: 10px;
          border: 7px solid #fff;
          border-color: transparent;
          border-top-color: #fff;
        }
      }
      .info_text {
        margin-top: 70px;
        font-size: 14px;
        width: calc(100% - 430px);
        li {
          width: 23.8%;
          padding-top: 20px;
          color: #999;
          .val {
            color: rgb(0, 182, 57);
            font-size: 17px;
          }
        }
      }
    }
  }
}
</style>
