<template>
  <div class="pull_down_box">
    <el-select
      filterable
      placeholder=''
      v-model="name"
      :title='name'
      :class="`device_${type}${index}_box`"
      @focus='getDeviceList'
      @change="changeDevice"
      @visible-change="switchVisible"
      v-scroll='bindScroll'
      remote
      :remote-method='searchDevice'
      :disabled='!disable||status==5||status==1'
      ref='device'>
      <el-option
        v-for="(item, index) in deviceList"
        :key="index"
        :label="item.name | filterName"
        :value="item.peerId"
        :title='item.name'>
      </el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  props: {
    deviceList: {
      type: Array,
      default: () => {
        return []
      }
    },
    name: {
      type: String,
      default: ''
    },
    index: {
      type: Number
    },
    type: {
      type: String
    },
    disable: {
      type: Boolean,
      default: false
    },
    status: {
      type: Number
    }
  },
  data () {
    return {
      bindScroll: {
        onScroll: this.handleDeviceScroll,
        distance: 10,
        directive: '.el-select-dropdown__wrap.el-scrollbar__wrap'
      },
      isShow: {
        device: false
      }
    }
  },
  filters: {
    // filter name
    filterName (name) {
      if (name && name.length > 6) {
        return `${name.slice(0, 6)}...`
      }
      return name
    }
  },
  mounted () {
    this.bindEvent()
  },
  methods: {
    // 绑定事件
    bindEvent () {
      $(`.device_${this.type}${this.index}_box .el-input__suffix .el-input__icon`).click(() => {
        if (this.isShow.device) {
          setTimeout(() => {
            this.$refs.device.blur()
          }, 10)
        }
      })
    },
    // 处理滚动
    handleDeviceScroll () {
      this.$emit('addPageNum', this.type, this.index)
    },
    changeDevice (val) {
      let selectedDevice
      for (const v of this.deviceList) {
        if (v.peerId == val) {
          selectedDevice = v
          break
        }
      }
      this.$emit('updateDevice', selectedDevice, this.index, this.type)
    },
    // 搜索device列表
    searchDevice (value) {
      this.getDeviceList(value)
    },
    // 查询device列表
    getDeviceList (condition) {
      condition = typeof condition != 'string' ? '' : condition
      this.$emit('getDeviceList', condition, this.index)
    },
    // 切换显示下拉列表
    switchVisible () {
      this.arrowAnimation(this.isShow.device ? 'remove' : 'add')
      this.isShow.device = !this.isShow.device
    },
    // 添加动画
    arrowAnimation (operation) {
      $(`.device_${this.type}${this.index}_box .el-input__suffix .el-input__icon`)[`${operation}Class`]('is-reverse')
    }
  }
}
</script>
<style lang="less" scoped>
.pull_down_box {
  ::v-deep .el-select .el-input__inner {
    background: transparent;
    border: none;
    padding: 0;
    height: 30px !important;
    text-align: center;
    cursor: default;
    color: #fff;
    // padding-right: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ::v-deep .el-select {
    .el-select__caret {
      line-height: 30px;
    }
    .el-icon-:before {
      content: '\E60C';
    }
    .el-icon- {
      font-size: 16px;
    }
    .is-reverse.el-icon-:before {
      transform: rotate(-180deg);
    }
  }
}
</style>
