class creatingInfo {
  constructor (arr) {
    this.recvs = arr
  }

  update (param) {
    Object.keys(param).forEach((k, i) => {
      this[k] = param[k]
    })
  }

  clearAll () {
    Object.keys(this).forEach((k, i) => {
      this.recvs = []
      if (!'recvs, update, clearAll'.includes(k)) delete this[k]
    })
  }
}
export default creatingInfo
