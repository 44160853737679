<template>
  <div class="scroll_box">
    <ul>
      <li
        class='ellipsis'
        :draggable="item.type.toLowerCase()!='r'?true:false"
        v-for='(item, index) in deviceList'
        :key='index'
        :class='{antline:chooseIndex==index}'
        :data-device='JSON.stringify(item)'
        :title='item.peerId'
        :data-index='index'
        @click='chooseDevice(item, index)'>
        <span class='f_left ellipsis'>{{item.name}}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    deviceList: {
      type: Array,
      default: () => {
        return []
      }
    },
    chooseIndex: {
      type: Number
    }
  },
  methods: {
    chooseDevice (item, index) {
      this.$emit('chooseDevice', item, index)
    }
  }
}
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
  background: transparent;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #ccc;
}
.scroll_box {
  height: 100%;
  font-size: 16px;
  ul {
    height: 100%;
    overflow-y: scroll;
  }
  li {
    height: 50px;
    line-height: 50px;
    padding-left: 24px;
    max-width: 250px;
    box-sizing: content-box;
    span {
      position: relative;
      z-index: 1;
      width: 100%;
    }
  }
}
</style>
